import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';

export const useAuthorization = (groups = []) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [authorized, setAuthorized] = useState(false);
  
  
  useEffect(() => {
  if (!user) {
    setAuthorized(false);
    return;
  }

  const currentAccount = user.getSignInUserSession()?.getIdToken();
  if (groups && groups.length > 0) {
    const isAuthorized = groups.some((group) =>
      currentAccount?.payload["cognito:groups"].includes(group)
    );
    setAuthorized(isAuthorized);
  } else {
    setAuthorized(true);
  }
}, [user, groups]);

return authorized;

};