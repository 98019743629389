import {Environment, OrbitControls, useGLTF} from "@react-three/drei";
import React, {Suspense, useRef} from "react";
import {Canvas} from "@react-three/fiber";
useGLTF.preload('/3d_model/oilrig_1.glb')
export default function Oilrig1Model(props) {
    const { nodes, materials } = useGLTF('/3d_model/oilrig_1.glb')
    const ref = useRef()
    return (
        // <Canvas shadows camera={{ position: [20, 25, 0], fov: 110 }}>
        <Canvas shadows camera={{ position: [-15, 20, 16], fov: 100 }}>
            <ambientLight intensity={0.7} />
            {/*<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />*/}
            <Suspense fallback={null}>
                <group
                    {...props}
                    dispose={null}
                    ref={ref}
                    onClick={(e) => {
                        e.stopPropagation()
                        props.setElementSelected(e.object.material.name)
                    }}>
                    <group position={[0, 28, 39]}>
                        <group position={[-32.99, 14.29, -5.03]}>
                            <mesh geometry={nodes.Cube230.geometry} material={materials.Bianco} />
                            <mesh geometry={nodes.Cube230_1.geometry} material={materials.zincato} />
                        </group>
                        <group position={[-29.43, 8.44, -34.92]}>
                            <mesh geometry={nodes.Cube170.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube170_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[3.05, 12.44, -11.21]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube184.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube184_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[-28.16, 4.23, 17.81]}>
                            <mesh geometry={nodes.Cube025_1.geometry} material={materials.container4F} />
                            <mesh geometry={nodes.Cube025_2.geometry} material={materials.container4P} />
                        </group>
                        <group position={[13.5, 0.23, 17.81]}>
                            <mesh geometry={nodes.Cube043.geometry} material={materials.container1F} />
                            <mesh geometry={nodes.Cube043_1.geometry} material={materials.container1P} />
                        </group>
                        <group position={[-13.86, 13.75, -6.59]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube190.geometry} material={materials.container3F} />
                            <mesh geometry={nodes.Cube190_1.geometry} material={materials.container3P} />
                        </group>
                        <group position={[-11.92, 13.75, -15.7]} rotation={[Math.PI, 0, Math.PI]}>
                            <mesh geometry={nodes.Cube191.geometry} material={materials.container2F} />
                            <mesh geometry={nodes.Cube191_1.geometry} material={materials.container2P} />
                        </group>
                        <group position={[18.62, 13.75, -18.27]} rotation={[Math.PI, 0, Math.PI]}>
                            <mesh geometry={nodes.Cube192.geometry} material={materials.container2F} />
                            <mesh geometry={nodes.Cube192_1.geometry} material={materials.container2P} />
                        </group>
                        <group position={[13.71, 13.75, -16.73]} rotation={[0, -1.57, 0]}>
                            <mesh geometry={nodes.Cube193.geometry} material={materials.container3F} />
                            <mesh geometry={nodes.Cube193_1.geometry} material={materials.container3P} />
                        </group>
                        <group position={[-24.09, 9.75, -18.23]}>
                            <mesh geometry={nodes.Cube194.geometry} material={materials.container2F} />
                            <mesh geometry={nodes.Cube194_1.geometry} material={materials.container2P} />
                        </group>
                        <group position={[-36.52, 9.75, -51.79]} rotation={[0, -1.57, 0]}>
                            <mesh geometry={nodes.Cube195.geometry} material={materials.container1F} />
                            <mesh geometry={nodes.Cube195_1.geometry} material={materials.container1P} />
                        </group>
                        <group position={[-25.67, 9.75, -23.61]} rotation={[0, -1.57, 0]}>
                            <mesh geometry={nodes.Cube196.geometry} material={materials.container3P} />
                            <mesh geometry={nodes.Cube196_1.geometry} material={materials.container3F} />
                        </group>
                        <group position={[-34.19, 9.75, -18.24]}>
                            <mesh geometry={nodes.Cube197.geometry} material={materials.container1F} />
                            <mesh geometry={nodes.Cube197_1.geometry} material={materials.container1P} />
                        </group>
                        <group position={[-19.41, 0.25, -8.05]}>
                            <mesh geometry={nodes.Cube169.geometry} material={materials.Lamiera_rossa} />
                            <mesh geometry={nodes.Cube169_1.geometry} material={materials.solaio} />
                        </group>
                        <mesh geometry={nodes.contenitore_1003.geometry} material={materials.Lamiera_rossa_logo} position={[-54.23, 4.26, 13.1]} />
                        <group position={[-31.55, 14.29, -12.03]}>
                            <mesh geometry={nodes.Cube231.geometry} material={materials.Bianco} />
                            <mesh geometry={nodes.Cube231_1.geometry} material={materials.zincato} />
                        </group>
                        <mesh geometry={nodes.locale001.geometry} material={materials.Lamiera_verde} position={[-47, 0.44, 8]} />
                        <group position={[8, 0.44, 8]}>
                            <mesh geometry={nodes.Cube147.geometry} material={materials.Lamiera_blu} />
                            <mesh geometry={nodes.Cube147_1.geometry} material={materials.solaio} />
                        </group>
                        <group position={[-8, 0.44, 8]}>
                            <mesh geometry={nodes.Cube148.geometry} material={materials.Lamiera_blu} />
                            <mesh geometry={nodes.Cube148_1.geometry} material={materials.solaio} />
                        </group>
                        <group position={[8, 16.46, 8]}>
                            <mesh geometry={nodes.Cube149.geometry} material={materials.Lamiera_blu} />
                            <mesh geometry={nodes.Cube149_1.geometry} material={materials.solaio} />
                        </group>
                        <mesh geometry={nodes.macchinari001.geometry} material={materials.zincato} position={[-36.17, 9, -47.66]} rotation={[-Math.PI, 0, -Math.PI]} />
                        <mesh geometry={nodes.macchinari002.geometry} material={materials.zincato} position={[4.95, 13.1, -9.54]} rotation={[-Math.PI, 0, -Math.PI]} />
                        <mesh geometry={nodes.parapetto004.geometry} material={materials['parete bianca']} position={[8.11, 16.98, 8.04]} rotation={[0, -Math.PI / 2, 0]} />
                        <group position={[-31.05, 7.75, 13.59]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube016.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube016_1.geometry} material={materials.luce} />
                        </group>
                        <group position={[-29.51, 0.83, 17.03]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube032.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube032_1.geometry} material={materials.luce} />
                            <mesh geometry={nodes.Cube032_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-29.51, 8.83, 17.03]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube035.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube035_1.geometry} material={materials.luce} />
                            <mesh geometry={nodes.Cube035_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-29.51, 12.83, 17.03]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube042.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube042_1.geometry} material={materials.luce} />
                            <mesh geometry={nodes.Cube042_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-57.48, 0.98, 13.27]} rotation={[0, Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube165.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube165_1.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Cube165_2.geometry} material={materials.luce} />
                        </group>
                        <mesh geometry={nodes.percorso004.geometry} material={materials.zincato} position={[-4.87, 8.74, 19.03]} />
                        <mesh geometry={nodes.antenne.geometry} material={materials.struttura} position={[-35.89, 17.37, -3.07]} />
                        <group position={[-36.57, 2.71, 17.53]}>
                            <mesh geometry={nodes.Cube027.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube027_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[7.8, 2.71, 17.53]} rotation={[Math.PI, 0, Math.PI]}>
                            <mesh geometry={nodes.Cube036.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube036_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-13.26, 14.51, 13.51]}>
                            <mesh geometry={nodes.Cube151.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube151_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[4.73, 21.46, 11.65]}>
                            <mesh geometry={nodes.Cube150.geometry} material={materials.Lamiera_rossa} />
                            <mesh geometry={nodes.Cube150_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-47.28, 7.88, 13.25]}>
                            <mesh geometry={nodes.Cylinder012.geometry} material={materials.gru_rosso} />
                            <mesh geometry={nodes.Cylinder012_1.geometry} material={materials.inox} />
                        </group>
                        <mesh geometry={nodes.serbatoi014.geometry} material={materials.zincato} position={[-56.08, 8.65, 12.69]} />
                        <mesh geometry={nodes.serbatoi015.geometry} material={materials.zincato} position={[-14.02, 15.86, 6.35]} />
                        <mesh geometry={nodes.sostegni001.geometry} material={materials.struttura} position={[-8.86, 7.87, 20.7]} />
                        <mesh geometry={nodes.tubi006.geometry} material={materials.inox} position={[-37.81, 2.9, 14.01]} rotation={[Math.PI / 2, 0, 0]} />
                        <mesh geometry={nodes.tubi007.geometry} material={materials.inox} position={[-34.64, 2.24, 14.35]} rotation={[Math.PI / 2, 0, 0]} />
                        <mesh geometry={nodes.tubi008.geometry} material={materials.inox} position={[-26.83, 2.24, 14.35]} rotation={[Math.PI / 2, 0, 0]} />
                        <mesh geometry={nodes.tubi009.geometry} material={materials.inox} position={[-31.48, 2.9, 14.01]} rotation={[Math.PI / 2, 0, 0]} />
                        <mesh geometry={nodes.tubi010.geometry} material={materials.inox} position={[-27.25, 6.4, 13.71]} />
                    </group>
                    <group position={[20, 30.01, -56.91]} rotation={[Math.PI, -1.43, Math.PI]}>
                        <group position={[0, 46.21, 21.54]} rotation={[0.7, 0, 0]}>
                            <mesh geometry={nodes.Cube040.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube040_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[0.2, 24.46, -0.96]}>
                            <mesh geometry={nodes.Cube030.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube030_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[0.34, 20.53, -1.62]}>
                            <mesh geometry={nodes.Cube037.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube037_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube037_2.geometry} material={materials.vetro} />
                            <mesh geometry={nodes.Cube037_3.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Cube037_4.geometry} material={materials.zincato} />
                        </group>
                    </group>
                    <group position={[20, 30.01, 56.93]} rotation={[0, -1.13, 0]}>
                        <group position={[0.63, 20.53, -1.53]} rotation={[0, -0.18, 0]}>
                            <mesh geometry={nodes.Cube037.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube037_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube037_2.geometry} material={materials.vetro} />
                            <mesh geometry={nodes.Cube037_3.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Cube037_4.geometry} material={materials.zincato} />
                        </group>
                        <group position={[-5.55, 34.24, 29.72]} rotation={[1.23, -0.06, 0.17]}>
                            <mesh geometry={nodes.Cube038.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube038_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[0.38, 24.46, -0.9]} rotation={[0, -0.18, 0]}>
                            <mesh geometry={nodes.Cube023.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube023_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[-9.92, 17.48, 53.03]} rotation={[0, -0.22, 0]}>
                            <mesh geometry={nodes.Cube013.geometry} material={materials.container3F} />
                            <mesh geometry={nodes.Cube013_1.geometry} material={materials.container3P} />
                        </group>
                        <mesh geometry={nodes.container020.geometry} material={materials['gru giallo']} position={[-9.92, 17.38, 53.03]} rotation={[0, -0.22, 0]} />
                    </group>
                    <group position={[-57.11, 30.01, -13.93]} rotation={[0, 0.71, 0]}>
                        <group position={[0.34, 20.53, -1.62]}>
                            <mesh geometry={nodes.Cube037.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube037_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube037_2.geometry} material={materials.vetro} />
                            <mesh geometry={nodes.Cube037_3.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Cube037_4.geometry} material={materials.zincato} />
                        </group>
                        <group position={[0, 46.21, 21.54]} rotation={[0.7, 0, 0]}>
                            <mesh geometry={nodes.Cube012.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube012_1.geometry} material={materials.funi} />
                        </group>
                        <group position={[0.2, 24.46, -0.96]}>
                            <mesh geometry={nodes.Cube026.geometry} material={materials['gru giallo']} />
                            <mesh geometry={nodes.Cube026_1.geometry} material={materials.funi} />
                        </group>
                    </group>
                    <group position={[0, 40.68, 0]}>
                        <mesh geometry={nodes.container004.geometry} material={materials.Lamiera_gialla} position={[-6.47, 1.09, 10.23]} />
                        <mesh geometry={nodes.container005.geometry} material={materials.Lamiera_blu} position={[-5.16, 1.09, -10.08]} />
                        <mesh geometry={nodes.container006.geometry} material={materials.Lamiera_verde} position={[-12.1, 1.09, 4.68]} />
                        <mesh geometry={nodes.Derrik_1.geometry} material={materials.struttura2} position={[-6, 0.3, 6]} scale={0.6} />
                        <mesh geometry={nodes.Derrik_2.geometry} material={materials.struttura2} position={[0, 36.08, 0.01]} scale={0.6} />
                        <mesh geometry={nodes.Derrik_3.geometry} material={materials.struttura2} position={[0.03, 29.4, 0.1]} rotation={[0, 0, -Math.PI / 2]} scale={0.6} />
                        <group position={[-3.4, 61.65, 3.4]}>
                            <mesh geometry={nodes.Cube062.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube062_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube062_2.geometry} material={materials.struttura2} />
                        </group>
                        <mesh geometry={nodes.Derrik_5.geometry} material={materials.funi} position={[0, 1.9, 0]} scale={[1, 0.35, 1]} />
                        <mesh geometry={nodes.Derrik_6.geometry} material={materials.zincato} position={[-0.79, 44.49, -3.2]} />
                        <mesh geometry={nodes.Derrik_7.geometry} material={materials.zincato} position={[0, 44.49, 0]} scale={[4.4, 1, 4.4]} />
                        <group position={[7.49, 11.31, -3.17]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube002.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube002_1.geometry} material={materials.Lamiera_verde} />
                        </group>
                        <mesh geometry={nodes.Derrik_9.geometry} material={materials.Lamiera_blu_scritta} position={[0, 12.74, 0.01]} />
                        <mesh geometry={nodes.pavimento.geometry} material={materials.solaio} position={[0, -40.24, 0]} />
                    </group>
                    <mesh geometry={nodes.corde.geometry} material={materials.funi} position={[46.06, 10.75, 21.09]} />
                    <mesh geometry={nodes.corde001.geometry} material={materials.funi} position={[-50.08, 10.75, 16.6]} rotation={[0, 1.56, 0]} />
                    <mesh geometry={nodes.corde002.geometry} material={materials.funi} position={[-52.76, 10.75, 13.65]} rotation={[-Math.PI, 0.78, -Math.PI]} />
                    <mesh geometry={nodes.passerella019.geometry} material={materials.zincato} position={[-20, 24.74, 30.15]} scale={[4.4, 1, 4.4]} />
                    <mesh geometry={nodes.passerella020.geometry} material={materials.zincato} position={[10.02, 24.74, 19.97]} rotation={[0, Math.PI / 2, 0]} scale={[4.4, 1, 4.4]} />
                    <mesh geometry={nodes.pila.geometry} material={materials.Pila} position={[-20, 0, 20]} />
                    <mesh geometry={nodes.pila001.geometry} material={materials.getto_acqua} position={[-20.02, 0.41, 20]} />
                    <mesh geometry={nodes.rampa002.geometry} material={materials.zincato} position={[-27.07, 25.69, 30.13]} />
                    <mesh geometry={nodes.rampa003.geometry} material={materials.zincato} position={[-28.55, 27.69, 32.68]} rotation={[0, Math.PI / 2, 0]} />
                    <mesh geometry={nodes.rampa004.geometry} material={materials.zincato} position={[-11.43, 27.69, 32.73]} rotation={[0, Math.PI / 2, 0]} />
                    <mesh geometry={nodes.rampa005.geometry} material={materials.zincato} position={[-12.92, 25.69, 30.16]} rotation={[Math.PI, 0, Math.PI]} />
                    <mesh geometry={nodes.rampa006.geometry} material={materials.zincato} position={[10.03, 25.69, 12.89]} rotation={[0, -1.57, 0]} />
                    <mesh geometry={nodes.rampa007.geometry} material={materials.zincato} position={[12.59, 27.69, 11.4]} rotation={[Math.PI, 0, Math.PI]} />
                    <mesh geometry={nodes.rampa008.geometry} material={materials.zincato} position={[12.55, 27.69, 28.52]} rotation={[Math.PI, 0, Math.PI]} />
                    <mesh geometry={nodes.rampa009.geometry} material={materials.zincato} position={[10, 25.69, 27.04]} rotation={[0, Math.PI / 2, 0]} />
                    <group position={[40, 32.24, -40]}>
                        <mesh geometry={nodes.biscaggina_1.geometry} material={materials.zincato} />
                        <mesh geometry={nodes.biscaggina_2.geometry} material={materials.zincato} />
                        <mesh geometry={nodes.coperchio.geometry} material={materials.serbatoi} position={[0, 17, 0]} />
                        <mesh geometry={nodes.coperchio001.geometry} material={materials.serbatoi} position={[0, -16, 0]} />
                        <mesh geometry={nodes.Cylinder011.geometry} material={materials.Lamiera_gialla} position={[-12.8, 4.51, -16.31]} />
                        <mesh geometry={nodes.locale004.geometry} material={materials.Lamiera_verde} position={[-11.05, 1.5, -14.53]} />
                        <mesh geometry={nodes.parapetto001.geometry} material={materials.zincato} position={[0, -16, 0]} />
                        <group position={[-3.48, 0.73, -18.57]}>
                            <mesh geometry={nodes.Cube266.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube266_1.geometry} material={materials.solaio} />
                        </group>
                        <mesh geometry={nodes.serbatoi001.geometry} material={materials.inox} position={[11.88, 3.56, -14.99]} />
                        <mesh geometry={nodes.serbatoio_principale.geometry} material={materials.serbatoi} />
                        <mesh geometry={nodes.serbatoio_principale001.geometry} material={materials.serbatoi_scritta} />
                    </group>
                    <group position={[-11.2, 40.44, -46.66]}>
                        <group position={[-22.82, 5.06, -5.26]}>
                            <mesh geometry={nodes.Cube140.geometry} material={materials.container1P} />
                            <mesh geometry={nodes.Cube140_1.geometry} material={materials.container1F} />
                        </group>
                        <group position={[-17.21, 5.06, -3.48]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube141.geometry} material={materials.container4P} />
                            <mesh geometry={nodes.Cube141_1.geometry} material={materials.container4F} />
                        </group>
                        <group position={[-38.92, -2.91, -10.88]}>
                            <mesh geometry={nodes.Cube142.geometry} material={materials.container4P} />
                            <mesh geometry={nodes.Cube142_1.geometry} material={materials.container4F} />
                        </group>
                        <mesh geometry={nodes.locale002.geometry} material={materials.Lamiera_blu} position={[-19.75, -11.99, -0.33]} />
                        <mesh geometry={nodes.locale003.geometry} material={materials.Lamiera_blu_scritta} position={[-35.75, -11.99, -5.83]} />
                        <mesh geometry={nodes.locale010.geometry} material={nodes.locale010.material} position={[31.21, -2.49, 6.65]} />
                        <mesh geometry={nodes.locale011.geometry} material={materials.Lamiera_blu} position={[12.18, -11.99, 1.09]} />
                        <mesh geometry={nodes.locale012.geometry} material={materials.Lamiera_blu} position={[-1.3, -11.99, 17.11]} />
                        <mesh geometry={nodes.locale013.geometry} material={materials.Lamiera_blu} position={[27.19, -11.99, -1.06]} />
                        <mesh geometry={nodes.macchinari.geometry} material={nodes.macchinari.material} position={[-21.7, 4.71, -1.04]} rotation={[0, -Math.PI / 2, 0]} scale={1.76} />
                        <group position={[8.51, -11.97, -12.53]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Plane010.geometry} material={materials.struttura} />
                            <mesh geometry={nodes.Plane010_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Plane010_2.geometry} material={materials['gru giallo']} />
                        </group>
                        <group position={[8.51, -12.17, -12.53]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube188.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube188_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube188_2.geometry} material={materials.gru_rosso} />
                            <mesh geometry={nodes.Cube188_3.geometry} material={materials.Lamiera_rossa} />
                        </group>
                        <mesh geometry={nodes.Montacarichi_2004.geometry} material={materials.zincato} position={[13.16, 4.85, -12.6]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.parapetto002.geometry} material={materials.zincato} position={[-35.66, -3.72, -5.84]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.parapetto003.geometry} material={materials.zincato} position={[-19.66, 4.27, -0.34]} rotation={[0, -Math.PI / 2, 0]} />
                        <group position={[-44.62, -11.48, -3.07]} rotation={[0, -1.57, 0]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube022.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube022_1.geometry} material={materials.luce} />
                        </group>
                        <mesh geometry={nodes.passerella016.geometry} material={materials.zincato} position={[-43.84, -3.3, 3.75]} rotation={[0, -1.57, 0]} scale={[4.4, 1, 4.4]} />
                        <mesh geometry={nodes.passerella018.geometry} material={materials.zincato} position={[0.75, -11.69, -10.16]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.4, 1, 4.4]} />
                        <mesh geometry={nodes.percorso001.geometry} material={materials.zincato} position={[-39.2, -8.43, -15.22]} />
                        <mesh geometry={nodes.percorso002.geometry} material={materials.zincato} position={[17.96, 3.34, -1.81]} rotation={[0, -Math.PI / 2, 0]} />
                        <group position={[-45.56, -12.19, -10.08]} rotation={[Math.PI, 0, Math.PI]}>
                            <mesh geometry={nodes.Cube157.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube157_1.geometry} material={materials.Bianco} />
                        </group>
                        <mesh geometry={nodes.rampa_singola005.geometry} material={materials.zincato} position={[-28.32, -1.86, -1.15]} rotation={[0, Math.PI / 2, 0]} />
                        <mesh geometry={nodes.rampa_singola008.geometry} material={materials.zincato} position={[-25.09, -9.81, -10.67]} />
                        <mesh geometry={nodes.rampa_singola009.geometry} material={materials.zincato} position={[-47.42, -9.81, 26.14]} rotation={[0, 1.57, 0]} />
                        <mesh geometry={nodes.rampa_singola013.geometry} material={materials.zincato} position={[15.37, 2.59, -9.66]} rotation={[-Math.PI, 0, -Math.PI]} />
                        <mesh geometry={nodes.scarico.geometry} material={materials.Lamiera_rossa} position={[-24.24, 5.26, 4.11]} />
                        <mesh geometry={nodes.serbatoi002.geometry} material={materials.inox} position={[0, 2, 0]} />
                        <mesh geometry={nodes.serbatoi003.geometry} material={materials.zincato} position={[8.18, -0.64, 11.64]} />
                        <mesh geometry={nodes.serbatoi004.geometry} material={materials.inox} position={[-4.69, 1.54, -0.92]} />
                        <mesh geometry={nodes.serbatoi005.geometry} material={materials.inox} position={[-0.83, 2, 9.88]} rotation={[0, -Math.PI / 2, 0]} />
                        <group position={[-45.23, 0.9, 19.71]}>
                            <mesh geometry={nodes.Cylinder004.geometry} material={materials.serbatoi} />
                            <mesh geometry={nodes.Cylinder004_1.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cylinder004_2.geometry} material={materials.inox} />
                        </group>
                        <group position={[-40.92, -0.84, -0.95]}>
                            <mesh geometry={nodes.Cylinder006.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cylinder006_1.geometry} material={materials.inox} />
                        </group>
                        <mesh geometry={nodes.serbatoi008.geometry} material={materials.inox} position={[-6.63, 1.54, 23.52]} />
                        <mesh geometry={nodes.serbatoi009.geometry} material={materials.Lamiera_verde} position={[-22.05, -9.97, 21.54]} />
                        <mesh geometry={nodes.serbatoi010.geometry} material={materials.zincato} position={[-41.81, -8.64, 26.32]} />
                        <mesh geometry={nodes.serbatoi011.geometry} material={materials.zincato} position={[13.49, -0.64, 20.21]} />
                        <mesh geometry={nodes.struttura001.geometry} material={materials.struttura2} position={[-44.74, -2.91, -8.35]} />
                        <mesh geometry={nodes.supporto_gru_1.geometry} material={materials.gru_rosso} position={[-43.82, -8.7, 32.71]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.supporto_gru_2.geometry} material={materials.gru_rosso} position={[31.22, -8.7, -8.35]} rotation={[Math.PI, 0, Math.PI]} />
                        <mesh geometry={nodes.tubi002.geometry} material={materials.inox} position={[14.89, -7.91, 27.34]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.tubi003.geometry} material={materials.inox} position={[35.82, 0, -7.58]} rotation={[-Math.PI / 2, 0, -Math.PI]} />
                        <mesh geometry={nodes.tubi004.geometry} material={nodes.tubi004.material} position={[-8.19, 0.47, -5.69]} rotation={[-Math.PI / 2, 0, 0]} scale={0.5} />
                        <mesh geometry={nodes.tubi005.geometry} material={materials.inox} position={[-8.68, -11.73, 26.38]} rotation={[0, 1.57, 0]} />
                        <mesh geometry={nodes.tubi012.geometry} material={materials.inox} position={[14.89, -7.91, 27.34]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.tubi013.geometry} material={materials.inox} position={[78.02, -7.91, 27.34]} rotation={[0, -Math.PI / 2, 0]} />
                    </group>
                    <group position={[-68.63, 51.77, 27.61]}>
                        <group position={[-12.04, -105.62, 0]}>
                            <mesh geometry={nodes.Cube007.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube007_1.geometry} material={materials.rete} />
                            <mesh geometry={nodes.Cube007_2.geometry} material={materials.Lamiera_rossa} />
                            <mesh geometry={nodes.Cube007_3.geometry} material={materials['parete bianca']} />
                        </group>
                        <mesh geometry={nodes.eliporto_2.geometry} material={materials.Lamiera_blu} position={[8.13, 0.26, 6.97]} rotation={[Math.PI / 2, 0, 0]} />
                        <group position={[-12.04, -105.62, 0]}>
                            <mesh geometry={nodes.Cube274.geometry} material={materials.eliporto} />
                            <mesh geometry={nodes.Cube274_1.geometry} material={materials.zincato} />
                        </group>
                        <group position={[1.26, 0.15, 10.95]}>
                            <mesh geometry={nodes.Cube028.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube028_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[5.3, -1.84, 10.95]}>
                            <mesh geometry={nodes.Cube029.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube029_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <mesh geometry={nodes.struttura.geometry} material={materials.struttura} position={[-12.04, -105.62, 0]} />
                        <mesh geometry={nodes.tubi.geometry} material={materials.inox} position={[8.13, 0.26, 6.97]} rotation={[Math.PI / 2, 0, 0]} />
                    </group>
                    <group position={[-55.02, 32.23, 13.98]} rotation={[0, -Math.PI / 2, 0]}>
                        <mesh geometry={nodes.Cube.geometry} material={materials['parete bianca']} />
                        <mesh geometry={nodes.Cube_1.geometry} material={materials.vetro} />
                        <mesh geometry={nodes.Cube_2.geometry} material={materials.zincato} />
                        <group position={[0, 1.54, 0]}>
                            <mesh geometry={nodes.Cube003.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube003_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <mesh geometry={nodes.alloggi_6.geometry} material={materials.struttura} />
                        <mesh geometry={nodes.antenne002.geometry} material={materials.struttura} position={[-1.39, 19.33, -5.37]} rotation={[0, Math.PI / 2, 0]} scale={0.6} />
                        <group position={[20.13, 16.26, -3.34]}>
                            <mesh geometry={nodes.Cube049.geometry} material={materials.container2F} />
                            <mesh geometry={nodes.Cube049_1.geometry} material={materials.container2P} />
                        </group>
                        <group position={[2.53, 16.26, -3.34]}>
                            <mesh geometry={nodes.Cube119.geometry} material={materials.container3F} />
                            <mesh geometry={nodes.Cube119_1.geometry} material={materials.container3P} />
                        </group>
                        <group position={[31.93, 16.87, 1.8]} rotation={[-Math.PI, 1.07, -Math.PI]}>
                            <mesh geometry={nodes.Cylinder025.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cylinder025_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[3.84, -3.69, 2.55]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube048.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube048_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-24.66, 0.73, 4.65]} rotation={[0, -1.57, 0]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube209.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube209_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-7.11, 0.73, -0.85]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube211.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube211_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-9.31, 6.71, 0.86]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube212.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube212_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <mesh geometry={nodes.Plane.geometry} material={materials.insegna} position={[17.17, 0.18, 6.14]} rotation={[0, Math.PI / 2, 0]} />
                        <group position={[-1.81, -2.75, 4.05]} rotation={[0, Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube018.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube018_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[31.23, 2.6, 2.37]} rotation={[0, Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube046.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube046_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-3.2, 2.6, 2.37]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube047.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube047_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[29.73, -2.75, 4.05]} rotation={[0, Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube019.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube019_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-22.66, 1.77, 5.43]} rotation={[Math.PI / 9, 0, 0]}>
                            <mesh geometry={nodes.Cube200.geometry} material={materials.Scialuppa} />
                            <mesh geometry={nodes.Cube200_1.geometry} material={materials.zincato} />
                        </group>
                    </group>
                    <group position={[39, 28, 39]}>
                        <mesh geometry={nodes.antenna001.geometry} material={nodes.antenna001.material} position={[11.16, 14.49, 14.68]} />
                        <mesh geometry={nodes.locale.geometry} material={materials.Lamiera_verde} position={[-11.98, 1.96, 12]} />
                        <group position={[-2.66, 0.47, 19.54]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Plane005.geometry} material={materials.struttura} />
                            <mesh geometry={nodes.Plane005_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Plane005_2.geometry} material={materials['gru giallo']} />
                        </group>
                        <group position={[-2.66, 4.47, 19.54]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube156.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube156_1.geometry} material={materials.gru_rosso} />
                            <mesh geometry={nodes.Cube156_2.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube156_3.geometry} material={materials.Lamiera_rossa} />
                        </group>
                        <group position={[1.98, 17.29, 19.47]} rotation={[0, -Math.PI / 2, 0]}>
                            <mesh geometry={nodes.Cube163.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube163_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[-12.92, 0.74, 17.03]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube160.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube160_1.geometry} material={materials.luce} />
                            <mesh geometry={nodes.Cube160_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[16.97, 0.74, 10.68]} rotation={[0, Math.PI / 2, 0]} scale={[4.4, 1, 4.4]}>
                            <mesh geometry={nodes.Cube232.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube232_1.geometry} material={materials.luce} />
                            <mesh geometry={nodes.Cube232_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[17.76, 0.25, 17.74]}>
                            <mesh geometry={nodes.Cube152.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube152_1.geometry} material={materials.Bianco} />
                            <mesh geometry={nodes.Cube152_2.geometry} material={materials['parete bianca']} />
                        </group>
                        <group position={[13.41, 2.62, 17.53]} rotation={[Math.PI, 0, Math.PI]}>
                            <mesh geometry={nodes.Cube159.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube159_1.geometry} material={materials['parete bianca']} />
                        </group>
                        <mesh geometry={nodes.rampa_singola007.geometry} material={materials.zincato} position={[17.47, 2.62, -15.16]} rotation={[0, -1.57, 0]} />
                        <mesh geometry={nodes.rampa_singola011.geometry} material={materials.zincato} position={[1.27, 15.06, 14.47]} rotation={[0, -1.57, 0]} />
                        <mesh geometry={nodes.serbatoi012.geometry} material={materials.zincato} position={[-13.93, 15.83, 12.91]} />
                        <mesh geometry={nodes.supporto_gru.geometry} material={materials.gru_rosso} position={[-19, 3.74, 16]} />
                        <mesh geometry={nodes.tubi011.geometry} material={materials.inox} position={[-12.35, -0.13, 17.56]} rotation={[Math.PI / 2, 0, 0]} />
                    </group>
                    <group position={[0, 26.24, 0]}>
                        <mesh geometry={nodes.controventi001.geometry} material={materials.struttura} position={[-20, 3.76, 55]} />
                        <mesh geometry={nodes.controventi002.geometry} material={materials.struttura} position={[-55, 3.76, -14]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.controventi003.geometry} material={materials.struttura} position={[-55, 3.76, -20]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.controventi.geometry} material={materials.struttura} position={[-55, 3.76, 26]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.piastre.geometry} material={materials.solaio} position={[-25.31, 2.11, 28.97]} />
                        <mesh geometry={nodes.pilastri.geometry} material={materials.struttura} position={[-54.99, 9.75, 54.99]} rotation={[0, 0, -Math.PI / 2]} />
                        <mesh geometry={nodes.pilastri001.geometry} material={materials.struttura} position={[50.01, 3.75, -55]} rotation={[0, 1.57, 0]} scale={[1.41, 1, 1]} />
                        <mesh geometry={nodes.pilastri002.geometry} material={materials.struttura} position={[55.01, 3.75, -28]} scale={[1.41, 1, 1]} />
                        <mesh geometry={nodes.reticolo_derrik.geometry} material={materials.struttura} position={[0.01, 1.75, 16.99]} />
                        <mesh geometry={nodes.reticolo_serbatoio.geometry} material={materials.struttura} position={[0.01, 1.75, -23.01]} />
                        <mesh geometry={nodes.travi_principali.geometry} material={materials.struttura2} position={[0, 1, 23]} />
                        <mesh geometry={nodes.travi_x.geometry} material={materials.struttura} position={[0.01, 1.75, 54.99]} />
                        <mesh geometry={nodes.travi_y.geometry} material={materials.struttura} position={[-54.99, 1.75, 0.01]} rotation={[0, -Math.PI / 2, 0]} />
                    </group>
                    <group position={[39, 0, 0]}>
                        <mesh geometry={nodes.container019.geometry} material={materials.Material} position={[12.88, 41.75, 9.68]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.contenitore002.geometry} material={materials.Lamiera_rossa} position={[8.05, 28.25, -3.5]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.contenitore003.geometry} material={materials.Lamiera_rossa} position={[-2.59, 40.45, 4.79]} />
                        <mesh geometry={nodes.contenitore_1006.geometry} material={materials.Lamiera_blu} position={[6.39, 47.44, 9.57]} />
                        <mesh geometry={nodes.contenitori.geometry} material={materials.Lamiera_rossa} position={[4.48, 48.06, 2.63]} rotation={[-Math.PI, 0, -Math.PI]} />
                        <mesh geometry={nodes.Cube025.geometry} material={nodes.Cube025.material} position={[10.87, 41.22, 4.49]} />
                        <mesh geometry={nodes.Cylinder024.geometry} material={materials.zincato} position={[12.8, 43.83, -2.44]} rotation={[0, 1.57, 0]} />
                        <mesh geometry={nodes.locale008.geometry} material={materials.Lamiera_verde} position={[-8.38, 47.49, 3.79]} />
                        <group position={[19.53, 28.47, 19.68]}>
                            <mesh geometry={nodes.Plane009.geometry} material={materials.struttura} />
                            <mesh geometry={nodes.Plane009_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Plane009_2.geometry} material={materials['gru giallo']} />
                        </group>
                        <group position={[19.53, 37.94, 19.68]}>
                            <mesh geometry={nodes.Cube187.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube187_1.geometry} material={materials.funi} />
                            <mesh geometry={nodes.Cube187_2.geometry} material={materials.gru_rosso} />
                            <mesh geometry={nodes.Cube187_3.geometry} material={materials.Lamiera_rossa} />
                        </group>
                        <mesh geometry={nodes.Montacarichi_2003.geometry} material={materials.zincato} position={[19.46, 45.29, 15.04]} />
                        <mesh geometry={nodes.parapetto.geometry} material={materials.zincato} position={[-8.3, 60, 3.77]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.parapetto_2.geometry} material={materials.zincato} position={[-8.3, 47.95, 5.76]} rotation={[0, -Math.PI / 2, 0]} />
                        <mesh geometry={nodes.rampa_singola010.geometry} material={materials.zincato} position={[17.47, 30.62, -19.7]} rotation={[0, -1.57, 0]} />
                        <mesh geometry={nodes.rampa_singola012.geometry} material={materials.zincato} position={[16.5, 43.06, 12.87]} rotation={[0, -1.57, 0]} />
                        <group position={[-12.92, 64.37, 7.98]}>
                            <mesh geometry={nodes.Cube185.geometry} material={materials.zincato} />
                            <mesh geometry={nodes.Cube185_1.geometry} material={materials.Lamiera_verde} />
                            <mesh geometry={nodes.Cube185_2.geometry} material={materials.inox} />
                        </group>
                        <mesh geometry={nodes.serbatoi.geometry} material={materials.zincato} position={[-13.42, 62.87, -1.49]} />
                        <group position={[-17.59, 40.45, -8.67]}>
                            <mesh geometry={nodes.Sphere.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Sphere_1.geometry} material={materials.inox} />
                        </group>
                        <group position={[-10.78, 40.45, 18.06]}>
                            <mesh geometry={nodes.Sphere001.geometry} material={materials['parete bianca']} />
                            <mesh geometry={nodes.Sphere001_1.geometry} material={materials.serbatoi} />
                        </group>
                        <mesh geometry={nodes.tubi001.geometry} material={materials.inox} position={[15.43, 34.04, 5.53]} />
                    </group>
                    <group position={[55.26, 33.41, 0]}>
                        <mesh geometry={nodes.esausto_1.geometry} material={materials.zincato} position={[17.45, 33.3, 0.01]} />
                        <mesh geometry={nodes.esausto_1001.geometry} material={materials.struttura2} position={[17.45, 33.3, 0.01]} />
                        <mesh geometry={nodes.esausto_1002.geometry} material={materials.struttura2} position={[17.45, 33.3, 0.01]} />
                        <mesh geometry={nodes.esausto_1003.geometry} material={materials.funi} position={[17.45, 33.3, 0.01]} />
                        <mesh geometry={nodes.serbatoio.geometry} material={materials.inox} position={[1.68, -2.53, -2.23]} rotation={[-Math.PI / 2, 0, 0]} />
                        <mesh geometry={nodes.sostegni.geometry} material={materials.struttura} position={[4.3, -5.07, 2]} />
                    </group>
                    <mesh geometry={nodes.marevicino.geometry} material={materials.oceano_agitato} position={[-106.72, 0, 102.56]} />
                </group>
                <Environment preset="city" />
                {/*<ContactShadows position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />*/}
            </Suspense>
            {/*<OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />*/}
            <OrbitControls maxPolarAngle={Math.PI / 2} minDistance={100} maxDistance={2000} enableZoom={true} enablePan={true} />
        </Canvas>

    )
}
