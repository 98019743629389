import React, { useState } from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { drawerMenuItems } from "./MenuConfig";
import { useAuthorization } from "../Auth/Hooks/DynamicAuthorizationHook";
import { useTheme } from "@emotion/react";

const DrawerItem = ({ itemKey, itemText, linkTo, groups }) => {
  const groupPermission = useAuthorization(groups);
  return (
    <>
      {groupPermission ? (
        <>
          <ListItem sx={{ width: 150 }} disablePadding >
            <ListItemButton
              key={itemKey}
              component="a"
              href={linkTo}
              disabled={!groupPermission}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  outline: 'none',
                },
              }}>
              <ListItemText primary={itemText} primaryTypographyProps={{ fontSize: "10px" }} />
            </ListItemButton>
          </ListItem>
        </>
      ) : (
        <Tooltip title={groupPermission ? null : "Non autorizzato"}>
          <ListItem sx={{ width: 150 }} disablePadding>
            <ListItemButton disabled={!groupPermission}>
              <ListItemText primary={itemText} primaryTypographyProps={{ fontSize: "10px" }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </>
  );
};

function DrawerNavigationMenu({ handleDrawerClose }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isClicked, setIsClicked] = useState(false);
  // al click setto isClicked su true e chiudo
  const handleClick = () => {
    setIsClicked(true);
    handleDrawerClose();
  }
  // al mouseLeave verifico isClicked,se true lo resetto, altrimenti chiudo
  const handleMouseLeave = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      handleDrawerClose();
    }
  }
  return (
    <>
      <Grid
        container
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent={'flex-start'}
        spacing={2}
        paddingTop={isSmallScreen ? 10 : 6}
        paddingLeft={isSmallScreen ? 4 : 23}
        onMouseLeave={handleMouseLeave}
      >
        {drawerMenuItems.map((menuItem, index) =>
          <Grid
            item
            key={menuItem.itemKey}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <List
              onClick={handleClick}
              sx={{ width: 200 }}
            >
              <DrawerItem
                itemKey={menuItem.itemKey}
                itemText={menuItem.itemText.toUpperCase()}
                linkTo={menuItem.linkTo}
                groups={menuItem.groups}
              />
              {menuItem.submenuItems.map(submenuItem => (
                <SubDrawerItem
                  key={submenuItem.itemKey}
                  itemKey={submenuItem.itemKey}
                  itemText={submenuItem.itemText}
                  linkTo={submenuItem.linkTo}
                  groups={submenuItem.groups}
                />
              ))}
            </List>
            {isSmallScreen && index !== drawerMenuItems.length - 1 && (
              <Divider  flexItem variant="middle" />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};


const SubDrawerItem = ({ itemKey, itemText, linkTo, groups }) => {
  const groupPermission = useAuthorization(groups);

  return (
    <>
      {groupPermission ? (
        <ListItem sx={{ width: 230 }} disablePadding >
          <ListItemButton
            key={itemKey}
            component="a"
            href={linkTo}
            disabled={!groupPermission}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-focusVisible': {
                outline: 'none',
              },
            }}
          >
            <ListItemText primary={itemText} primaryTypographyProps={{ fontSize: "10px" }} />
          </ListItemButton>
        </ListItem>
      ) : (
        <Tooltip title={groupPermission ? null : "Non autorizzato"}>
          <ListItem sx={{ width: 230 }} disablePadding>
            <ListItemButton disabled={!groupPermission}>
              <ListItemText primary={itemText} primaryTypographyProps={{ fontSize: "10px" }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </>
  );
};


export default DrawerNavigationMenu;