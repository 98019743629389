import { createBrowserRouter, useNavigate } from "react-router-dom";
import AddProject from "../../pages/AddProject";
import ListProjects from "../../pages/ListProjects";
import ModifyProject from "../../pages/ModifyProject";
// import ProjectInfrastructure from "../../pages/ProjectInfrastructure";
import AddInfrastructure from "../../pages/AddInfrastructure";
import AnalysisType from "../../pages/AnalysisType";
import AddAnalysisType from "../../pages/AddAnalysisType";
import AnalysisConfiguration from "../../pages/AnalysisConfiguration";
import ModifyAnalysis from "../../pages/ModifyAnalysis";
import AnalysisResults from "../../pages/AnalysisResults";
import AnalysisDetail from "../../pages/AnalysisDetail";
import ModifyInfrastructure from "../../pages/ModifyInfrastructure";
import RouteProtect from "../Auth/RouterProtector";

import projects from "../../data/projects.json";
import infrastructureData from "../../data/infrastructure.json";
import analysis from "../../data/analysis.json";

import "../../App.css";
// import Layout from "../../pages/Layout";
import HomePage from "../../pages/HomePage";
import ChangePassword from "../Auth/ChangePassword";
import { StyledLink } from "../Theme/styledComponents";
import AnalysisCatalogCreation from "../../pages/AnalysisCatalogCreation";
import { Typography } from "@mui/material";
import InfrastructureGrid from "../InfrastructureGrid";
// import InfrastructureDetailComponent from "../InfrastructureDetailComponent";
import StructureLayout from "../../pages/StructureLayout";

function loadData({ params }) {
  return new Promise((resolve, reject) => {
    console.log("Loading project data: ", params);
    const project = projects?.find((project) => project.id === params.id);
    const infrastructures = infrastructureData?.find(
      (infrastructure) => infrastructure.project_id === params.id
    );
    let analysisResults = [];

    if (project && infrastructures) {
      if (params.resultsId) {
        analysisResults = analysis?.find(
          (result) => result.id === params.resultsId
        );
      }
      // else {
      //   analysisResults = analysis.find(
      //     (result) => result.infrastructure_id === infrastructures.id
      //   );
      // }

      const projectData = {
        ...project,
        infrastructures: {
          ...infrastructures,
          analysisResults: analysisResults,
        },
      };

      console.log("Project Data:", projectData);
      resolve(projectData);
    } else {
      reject("Progetto non trovato");
    }
  });
}
function GoBack({ to, children, ...rest }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <StyledLink to={to} {...rest} onClick={handleClick}>
      {children}
    </StyledLink>
  );
}

const link = {
  bullet: (
    <span style={{ marginLeft: 8, marginRight: 8, fontSize: "16px" }}>
      &#x2022;
    </span>
  ),
  projectsList: (
    <StyledLink to={"project"}>
      <Typography variant="caption" color="text.primary">
        Progetti
      </Typography>
    </StyledLink>
  ),
};

const router = createBrowserRouter([
  {
    element: <StructureLayout />,
    children: [
      {
        path: "/",
        element: (
          <RouteProtect groups={["admin", "adminRead", "superAdmin", "user"]} component={HomePage} />
        ),
      },
      {
        path: "/changePassword",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={ChangePassword}
          />
        ),
        handle: {
          crumb: () => "Cambio password",
        },
      },
      {
        path: "/addProject",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AddProject}
          />
        ),
        handle: {
          crumb: () => "Aggiungi progetto",
        },
      },
      {
        path: "/creationAnalysis",
        element: <AnalysisCatalogCreation />,

        handle: {
          crumb: () => "Creazione catalogo analisi",
        },
      },
      {
        path: "project",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={ListProjects}
          />
        ),

        handle: {
          crumb: () => link.projectsList,
        },
        children: [
          {
            path: ":id",
            element: (
              <RouteProtect
                groups={["admin", "adminRead", "superAdmin", "user"]}
                component={ListProjects}
              />
            ),

            loader: loadData,
            handle: {
              crumb: (project) => project.name,
            },
          },
        ],
      },
      {
        path: "project/:id/modifyProject",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={ModifyProject}
          />
        ),

        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                <Typography variant="caption" color="text.primary">
                  {project.name.toUpperCase()}
                </Typography>
              </StyledLink>
              {link.bullet}
              Modifica Progetto
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={InfrastructureGrid}
          />
        ),

        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              {link.projectsList}
              {link.bullet}
              <StyledLink to={`project`}>{project.name}</StyledLink>
              {link.bullet}
              <StyledLink to={`/project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/add",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AddInfrastructure}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                {project.name}
              </StyledLink>
              {link.bullet}
              Aggiungi infrastruttura
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={InfrastructureGrid}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                {project.name}
              </StyledLink>
              {link.bullet}
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
              {link.bullet}
              {project.infrastructures.name}
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/modifyInfrastructure",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={ModifyInfrastructure}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                {project.name}
              </StyledLink>
              {link.bullet}
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              Modifica infrastruttura
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/analysisType",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AnalysisType}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                {project.name}
              </StyledLink>
              {link.bullet}
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}`}
              >
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              Tipi di analisi
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/addAnalysisType",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AddAnalysisType}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}`}>
                {project.name}
              </StyledLink>
              {link.bullet}
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}/analysisType`}
              >
                Tipi di analisi
              </StyledLink>
              {link.bullet}
              Aggiungi analisi
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/analysisConfiguration/:configurationId",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AnalysisConfiguration}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}`}
              >
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}/analysisType`}
              >
                Tipi di analisi
              </StyledLink>
              {link.bullet}
              Configurazione
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/modifyAnalysisType/:configurationId",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={ModifyAnalysis}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}`}
              >
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}/analysisType`}
              >
                Tipi di analisi
              </StyledLink>
              {link.bullet}
              Modifica configurazione
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/analysisType/:resultsId/analysisResult",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AnalysisResults}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}`}
              >
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}/analysisType`}
              >
                Tipi di analisi
              </StyledLink>
              {link.bullet}
              {"Analisi " +
                project.infrastructures.analysisResults.analysis_type}
            </>
          ),
        },
      },
      {
        path: "project/:id/projectInfrastructure/:infrastructureId/analysisType/:resultsId/analysisDetail",
        element: (
          <RouteProtect
            groups={["admin", "adminRead", "superAdmin", "user"]}
            component={AnalysisDetail}
          />
        ),
        loader: loadData,
        handle: {
          crumb: (project) => (
            <>
              <StyledLink to={`project/${project.id}/projectInfrastructure`}>
                Infrastrutture
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}`}
              >
                {project.infrastructures.name}
              </StyledLink>
              {link.bullet}
              <StyledLink
                to={`project/${project.id}/projectInfrastructure/${project.infrastructures.id}/analysisType`}
              >
                Tipi di analisi
              </StyledLink>
              {link.bullet}
              <GoBack to="">Analisi generali</GoBack>
              {link.bullet}
              Dettaglio analisi
            </>
          ),
        },
      },
    ],
  },
]);

export default router;
