import React, { useState, useEffect, useRef } from "react";
import {
  chartZoomOptions,
  createChartRefFunctions,
} from "./UI/ChartZoomControls";
import moment from "moment";
import { Divider, Grid } from "@mui/material";
import ThresholdsErrorChart from "./Charts/ThresholdsErrorChart";
import ThresholdsGlobalDamage from "./Charts/ThresholdsGlobalDamage";
import DriftMaxPTChart from "./Charts/DriftMaxPTChart";
import ThresholdsScopeDamage from "./Charts/ThresholdsScopeDamage";

const errorColors = [
  {
    borderColor: "rgba(255, 0, 0, 1)",
    backgroundColor: "rgba(255, 0, 0, 0.2)",
  },
  {
    borderColor: "rgba(255, 199, 71, 1)",
    backgroundColor: "rgba(255, 199, 71, 0.2)",
  },
  {
    borderColor: "rgba(220, 320, 60, 1)",
    backgroundColor: "rgba(220, 320, 60, 0.2)",
  },
];
function ThresholdsCrossCharts({ filteredResoultFound }) {
  const thresholdsScopeRefInstance = useRef(null);
  const thresholdsErrorRefInstance = useRef(null);
  const thresholdsGlobaleRefInstance = useRef(null);
  const thresholdsDriftMaxRefInstance = useRef(null);
  const thresholdsScopeRefFunctions = createChartRefFunctions(thresholdsScopeRefInstance);
  const thresholdsErrorRefFunctions = createChartRefFunctions(thresholdsErrorRefInstance);
  const thresholdsGlobaleRefFunctions = createChartRefFunctions(thresholdsGlobaleRefInstance);
  const thresholdsDriftMaxRefFunctions = createChartRefFunctions(thresholdsDriftMaxRefInstance);
  const [state, setState] = useState({
    scopeDamageLabels: [],
    scopeDamageDatasets: [],
    errorLabels: [],
    errorDatasets: [],
    semaforoLabels: [],
    semaforoData: [],
    driftMaxPTLabels: [],
    driftMaxPTdata: [],
  })


  useEffect(() => {
    let dates = [];
    let thresholdsIndex = [];
    let semafori = [];
    let drifts_max_PT = [];
    let errors = [];
    // let datiFiltrati  = [];
    // let datiRMS = [];
    // let datiSGf = [];
    // console.log("🚀 ~ file: ThresholdsCrossCharts.js:40 ~ useEffect ~ datiSGf:", datiSGf)
    filteredResoultFound.forEach((item, index) => {
      const date = moment.utc(item.result.data).format("DD/MM/YYYY HH:mm");
      dates.push(date);


      thresholdsIndex = thresholdsIndex.concat(item.result.indici_soglie);
      semafori.push(item.result.semaforo);
      drifts_max_PT.push(item.result.drift_max_PT);

      // datiFiltrati.push(item.result.max_acc[0]);
      // datiRMS.push(item.result.max_acc[1]);
      // datiSGf.push(item.result.max_acc[2]);
      /// creo oggetto per rinominare i tipi di errore
      const renameErrorType = {
        "Errore_num_canali": "Errore num. canali",
        "Errore_presenza_file": "Errore presenza file",
        "Errore_dati_incompleti": "Errore dati incompleti",
      };

      if (item.error && index === 0) {
        item.error.tipi_di_errore.forEach((errorType, errorIndex) => {
          /// se la chiave/stringa nel renameErrorType non è presente, utilizzo chiave/stringa originale
          const errorsLabel = renameErrorType[errorType] || errorType;
          errors.push({
            label: errorsLabel,
            data: filteredResoultFound.map(
              (item) => item.error.valore_errori[errorIndex]
            ),
            borderColor: errorColors[errorIndex].borderColor,
            backgroundColor: errorColors[errorIndex].backgroundColor,
          });
        });
      }
    });
    setState({
      scopeDamageLabels: dates,
      scopeDamageDatasets: thresholdsIndex,
      errorLabels: dates,
      errorDatasets: errors,
      semaforoLabels: dates,
      semaforoData: semafori,
      driftMaxPTLabels: dates,
      driftMaxPTdata: drifts_max_PT,

    });
  }, [filteredResoultFound]);
  return (
    <>
      <Grid
        item
        display='flex'
        flexDirection="column"
        gap={4}
      >
        <Grid item >
          <ThresholdsScopeDamage
            scopeDamageLabels={state.scopeDamageLabels}
            scopeDatasets={state.scopeDamageDatasets}
            zoomOptions={chartZoomOptions}
            chartRef={thresholdsScopeRefInstance}
            onResetZoom={thresholdsScopeRefFunctions.onResetZoom}
            onZoomPluse={thresholdsScopeRefFunctions.onZoomPluse}
            onZoomMinus={thresholdsScopeRefFunctions.onZoomMinus}
            onPanPluse={thresholdsScopeRefFunctions.onPanPluse}
            onPanMinus={thresholdsScopeRefFunctions.onPanMinus}
          />
        </Grid>

        <Divider variant="middle" />

        <Grid item >
          <ThresholdsGlobalDamage
            semaforoLabels={state.semaforoLabels}
            semaforoData={state.semaforoData}
            zoomOptions={chartZoomOptions}
            chartRef={thresholdsErrorRefInstance}
            onResetZoom={thresholdsErrorRefFunctions.onResetZoom}
            onZoomPluse={thresholdsErrorRefFunctions.onZoomPluse}
            onZoomMinus={thresholdsErrorRefFunctions.onZoomMinus}
            onPanPluse={thresholdsErrorRefFunctions.onPanPluse}
            onPanMinus={thresholdsErrorRefFunctions.onPanMinus}
          />
        </Grid>

        <Divider variant="middle" />

        <Grid item >
          <DriftMaxPTChart
            driftMaxPTLabels={state.driftMaxPTLabels}
            driftMaxPTdata={state.driftMaxPTdata}
            zoomOptions={chartZoomOptions}
            chartRef={thresholdsDriftMaxRefInstance}
            onResetZoom={thresholdsDriftMaxRefFunctions.onResetZoom}
            onZoomPluse={thresholdsDriftMaxRefFunctions.onZoomPluse}
            onZoomMinus={thresholdsDriftMaxRefFunctions.onZoomMinus}
            onPanPluse={thresholdsDriftMaxRefFunctions.onPanPluse}
            onPanMinus={thresholdsDriftMaxRefFunctions.onPanMinus}
          />
        </Grid>

        <Divider variant="middle" />
        
        <Grid item >
          <ThresholdsErrorChart
            errorLabels={state.errorLabels}
            errorDatasets={state.errorDatasets}
            zoomOptions={chartZoomOptions}
            chartRef={thresholdsGlobaleRefInstance}
            onResetZoom={thresholdsGlobaleRefFunctions.onResetZoom}
            onZoomPluse={thresholdsGlobaleRefFunctions.onZoomPluse}
            onZoomMinus={thresholdsGlobaleRefFunctions.onZoomMinus}
            onPanPluse={thresholdsGlobaleRefFunctions.onPanPluse}
            onPanMinus={thresholdsGlobaleRefFunctions.onPanMinus}
          />
        </Grid>
      </Grid>

    </>
  );
}

export default ThresholdsCrossCharts;
