import React, { useEffect, useState } from 'react';
import analysisData from '../data/analysis.json';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as deleteIcon } from '../icon/deleteIcon.svg';


function ModifyAnalysis() {

  const navigate = useNavigate();
  const { configurationId } = useParams();
  const [currentAnalysis, setCurrentAnalysis] = useState(null);

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: '',
      field_pc_id: '',
      plc_id: '',
      analysis_type: '',
      schedule_type: '',
      schedule_every_seconds: '',
      seconds_data_to_analyze: '',
      version: '',
    }
  });

  useEffect(() => {
    const analysis = analysisData.filter(analysis => analysis["id"] === configurationId)[0];
    setCurrentAnalysis(analysis);
    if (analysis) {
      for (const [key, value] of Object.entries(analysis)) {
        setValue(key, value);
      }
    }
  }, [configurationId, setValue]);

  const modifyAnalysis = (data) => {
    setCurrentAnalysis({
      ...currentAnalysis,
      ...data
    });
    console.log(data);
  };

  const handleReturn = () => {
    navigate(-1);
  }

  const handleReset = () => {
    reset();
  }

  return (
    <Grid item container direction='column'>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
        <Typography variant='h4' fontWeight={700} > Modifica analisi</Typography>
      </Box>

      <Divider variant='middle' sx={{ width: '100%', mt: 2, mb: 3 }} />

      <form onSubmit={handleSubmit(modifyAnalysis)}>
        <Grid container spacing={1} direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <TextField
                {...register('name', { required: 'Obbligatorio' })}
                label="Nome analisi"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                {...register('field_pc_id', { required: 'Obbligatorio' })}
                label="Id pc di campo"
                fullWidth
                error={!!errors.field_pc_id}
                helperText={errors.field_pc_id?.message}
              />
              <TextField
                {...register('plc_id', { required: 'Obbligatorio' })}
                label="Id plc"
                fullWidth
                error={!!errors.plc_id}
                helperText={errors.plc_id?.message}
              />
              <TextField
                {...register('analysis_type', { required: 'Obbligatorio' })}
                label="Tipo analisi"
                fullWidth
                error={!!errors.analysis_type}
                helperText={errors.analysis_type?.message}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <TextField
                {...register('schedule_type', { required: 'Obbligatorio' })}
                label="Schedule type"
                fullWidth
                error={!!errors.schedule_type}
                helperText={errors.schedule_type?.message}
              />
              <TextField
                {...register('schedule_every_seconds', { required: 'Obbligatorio' })}
                label="Schedule every seconds"
                fullWidth
                error={!!errors.schedule_every_seconds}
                helperText={errors.schedule_every_seconds?.message}
              />
              <TextField
                {...register('seconds_data_to_analyze', { required: 'Obbligatorio' })}
                label="Seconds data to analyze"
                fullWidth
                error={!!errors.seconds_data_to_analyze}
                helperText={errors.seconds_data_to_analyze?.message}
              />
              <TextField
                {...register('version', { required: 'Obbligatorio' })}
                label="Versione"
                fullWidth
                error={!!errors.version}
                helperText={errors.version?.message}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}> */}
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button
              onClick={handleReturn}
              startIcon={<SvgIcon component={deleteIcon} viewBox="0 0 17 17" />}
              variant="contained"
              sx={{
                backgroundColor: "secondary.main",
                '&:hover': {
                  backgroundColor: "secondary.hover"
                }
              }}  >
              Cancel
            </Button>
          </Stack>
          <Button
            onClick={handleReset}
            variant="text"
            sx={{
              textDecoration: "underline",
              backgroundColor: "transparent",
              color: "brightAzure.main",
              '&:hover': {
                color: "brightAzure.hover",
                backgroundColor: "transparent",
              }
            }}  >
            Reset form
          </Button>
        </Stack>
        {/* </Grid> */}
        {/* </Grid> */}
      </form>
    </Grid>
  );
}

export default ModifyAnalysis;
