import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDataGrid } from "../Theme/styledComponents";
import MyDataGrid from "../UI/DataGrid";

function TabellaFDDadmin({ analysisResult }) {

  const [fddAdminRows, setFddAdminRows] = useState([]);
  const [fddAdminColumns, setFddAdminColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState("80vw");


  useEffect(() => {
    let fdd_admin_columns = [
      {
        field: "measurement_name",
        headerName: "Name",
        flex: 1,
      },
    ];
    let fdd_admin_first_row = {
      id: "fdd_admin_first_row",
      measurement_name: "Freq.",
    };
    let fdd_admin_2_row = {
      id: "fdd_admin_2_row",
      measurement_name: "Freq. picco",
    };
    let fdd_admin_3_row = {
      id: "fdd_admin_3_row",
      measurement_name: "Freq. 2_svd",
    };
    let fdd_admin_4_row = {
      id: "fdd_admin_4_row",
      measurement_name: "Smorz.",
    };
    let fdd_admin_5_row = {
      id: "fdd_admin_5_row",
      measurement_name: "QA.",
    };
    let fdd_admin_6_row = {
      id: "fdd_admin_6_row",
      measurement_name: "QB.",
    };

    analysisResult.result.freq_FDD.forEach((fdd, index) => {
      fdd_admin_columns.push({
        field: "fdd_admin_" + index,
        headerName: "Modo " + (index + 1),
        flex: 1,
      });
      fdd_admin_first_row["fdd_admin_" + index] = fdd;
    });
    setFddAdminColumns(fdd_admin_columns);

    // Metto freq_FDD_picco
    analysisResult.result.freq_FDD_picco.forEach((fdd_picco, index) => {
      fdd_admin_2_row["fdd_admin_" + index] = fdd_picco;
    });
    // Metto freq_FDD_2vs
    analysisResult.result.freq_FDD_2vs.forEach((fdd_2vs, index) => {
      fdd_admin_3_row["fdd_admin_" + index] = fdd_2vs;
    });
    // Metto smorz_FDD
    analysisResult.result.smorz_FDD.forEach((smorz, index) => {
      fdd_admin_4_row["fdd_admin_" + index] = smorz;
    });
    // Metto Quality_fitting_PSD_A
    analysisResult.result.quality_fitting_PSD_A.forEach((qual_fit_a, index) => {
      fdd_admin_5_row["fdd_admin_" + index] = qual_fit_a;
    });
    // Metto Quality_fitting_PSD_B
    analysisResult.result.quality_fitting_PSD_B.forEach((qual_fit_b, index) => {
      fdd_admin_6_row["fdd_admin_" + index] = qual_fit_b;
    });

    let fdd_admin_rows = [];
    fdd_admin_rows.push(fdd_admin_first_row);
    fdd_admin_rows.push(fdd_admin_2_row);
    fdd_admin_rows.push(fdd_admin_3_row);
    fdd_admin_rows.push(fdd_admin_4_row);
    fdd_admin_rows.push(fdd_admin_5_row);
    fdd_admin_rows.push(fdd_admin_6_row);
    // Metto i modi_FDD
    analysisResult.result.modi_FDD.forEach((modi_fdd, index) => {
      let row = {
        id: "fdd-admin-row-" + index,
        measurement_name: "Sens. " + (index + 1),
      };
      modi_fdd.forEach((modo, i) => {
        row["fdd_admin_" + i] = modo;
      });
      fdd_admin_rows.push(row);
    });
    setFddAdminRows(fdd_admin_rows);
  }, [analysisResult]);

  useEffect(() => {
    let totalWidth = 0;
    fddAdminColumns.forEach((column) => {
      totalWidth += column.width;
    });
    setTableWidth(totalWidth);
  }, [fddAdminColumns]);

  return (
    <>
      <Box>
      <Typography variant='h5' color="text.secondary" fontWeight={600} >
      5. Tabella con i modi identificati dal metodo **FDD** (Frequency
        Domanin Decomposition) - SOLO PER SUPER ADMIN
        </Typography>

      </Box>
        <Box 
          id={"FDD-table-admin"} 
          sx={{
            height: 700,
            // width: tableWidth || "80vw",
            // maxWidth: "95vw",
        }}>
          <MyDataGrid
            rows={fddAdminRows}
            columns={fddAdminColumns}
            getCellClassName={(params) => {
              if (
                [
                  "fdd_admin_first_row",
                  "fdd_admin_2_row",
                  "fdd_admin_3_row",
                  "fdd_admin_4_row",
                  "fdd_admin_5_row",
                  "fdd_admin_6_row",
                ].includes(params.id)
              ) {
                return "first_row_table";
              }
              return "";
            }}
          />
        </Box>
    </>
  );
}

export default TabellaFDDadmin;