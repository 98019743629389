import analysis from "../data/analysis.json";
import { GridButton } from "../components/Theme/styledComponents";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { StyledLink } from "../components/Theme/styledComponents";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { ControlPoint } from "@mui/icons-material";
import MyDataGrid from "../components/UI/DataGrid";



function AnalysisType() {
  const theme = useTheme();
  const { id, infrastructureId } = useParams();
  const [analisysFound, setAnalisysFound] = useState([]);

  useEffect(() => {
    const arr = analysis.filter(a => a.infrastructure_id === infrastructureId);
    setAnalisysFound(arr);
  }, [infrastructureId]);

  const renderName = (params) => {
    const valueMap = {
      analisi_soglie: "Analisi Soglie",
      analisi_modale: "Analisi Modale",
    };

    const formattedValue = valueMap[params.value] || params.value;

    return (
      <Link
        style={{ color: theme.palette.smokeWhite.main}}
        to={`/project/${id}/projectInfrastructure/${infrastructureId}/analysisConfiguration/${params.row.id}`}>
        {formattedValue}
      </Link>
    );
  };

  const renderResult = (params) => {
    return (
      <>
          <GridButton 
            variant="contained" 
            component={StyledLink} 
            to={`${params.row.id}/analysisResult`} 
          >
            Risultati
          </GridButton>
      </>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: renderName
    },
    {
      field: "azioni",
      // headerName: "Azioni",
      flex: 0.5,
      align: "right",
      headerAlign: "right",
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: renderResult,
    },
  ];

  return (
    <>
      <Grid item
        sx={{
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }} >
        <Box 
          sx={{ 
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1 
              }} >
          <Typography variant='h4' fontWeight={700} >Analisi impostate</Typography>
          <Typography variant='subtitle2' fontWeight={400} >Elenco analisi</Typography>
        </Box>

        <Divider variant='middle' sx={{ width: '100%', mt: 2, mb: 2}}/>

        <Box sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          mt:2
        }} >

            <Button
              component={StyledLink}
              to={`/project/${id}/projectInfrastructure/${infrastructureId}/addAnalysisType`}
              variant="contained" 
              startIcon={<ControlPoint />}
            >
              AGGIUNGI ANALISI
            </Button>
        </Box>
        <Box 
          sx={{ 
            height: 300,
            width: "80%"
             }}>
          <MyDataGrid
            rows={analisysFound}
            columns={columns}
          />
        </Box>
      </Grid>
    </>
  );
}

export default AnalysisType;
