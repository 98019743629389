import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDataGrid } from "../Theme/styledComponents";
import MyDataGrid from "../UI/DataGrid";

function TabellaSSI({ analysisResult }) {
  const [ssiRows, setSssiRows] = useState([]);
  const [ssiColumns, setSsiColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState("80vw");

  useEffect(() => {
    let ssi_columns = [
      {
        field: "measurement_name",
        headerName: "Name",
        flex: 1
      },
    ];
    let ssi_first_row = {
      id: "ssi_first_row",
      measurement_name: "SSI",
    };

    analysisResult.result.freq_SSI.forEach((ssi, index) => {
      ssi_columns.push({
        field: "ssi_" + index,
        headerName: "Modo " + (index + 1),
        flex: 1
      });
      ssi_first_row["ssi_" + index] = ssi;
    });
    setSsiColumns(ssi_columns);
    let ssi_rows = [];
    ssi_rows.push(ssi_first_row);
    // Metto i modi_SSI
    analysisResult.result.modi_SSI.forEach((modi_ssi, index) => {
      let row = {
        id: "ssi-row-" + index,
        measurement_name: "Sens. " + (index + 1),
      };
      modi_ssi.forEach((modo, i) => {
        row["ssi_" + i] = modo;
      });
      ssi_rows.push(row);
    });
    setSssiRows(ssi_rows);
  }, [analysisResult]);

  useEffect(() => {
    let totalWidth = 0;
    ssiColumns.forEach((column) => {
      totalWidth += column.width;
    });
    setTableWidth(totalWidth);
  }, [ssiColumns]);

  return (
    <>
      <Box>
        <Typography variant='h5' color="text.secondary" fontWeight={600} >
          2. Tabella con i modi identificati dal metodo SSI (Stocastic Subspace
          Identification)
        </Typography>
      </Box>
      <Box
        id={"SSI-table"}
        sx={{
          height: 700,
          // width: tableWidth ,
          // width: tableWidth || "80vw",
          // maxWidth: "80vw",
        }}
      >
        <MyDataGrid
          rows={ssiRows}
          columns={ssiColumns}
          getCellClassName={(params) => {
            if (params.id === "ssi_first_row") {
              return "first_row_table";
            }
            return "";
          }}
        />
      </Box>
    </>
  );
}

export default TabellaSSI;
