import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
import { useTheme } from "@emotion/react";
import BoxCharts from "../UI/BoxCharts";

// 6.
function FirstPSDChart({
  firstSingularPSDValueData,
  firstSingularPSDValueLabels,
  zoomOptions,
  chartRef,
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const theme = useTheme();

  const first_singular_value_psd_config = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: theme.palette.white.main,
        }
      },
      title: {
        display: true,
        text: "Firs singular PSD value",
        color: theme.palette.white.main,
      },
      zoom: zoomOptions,
    },
    scales: {
      x: {
        ticks: {
          color: theme.palette.white.main,
        }
      },
      y: {
        ticks: {
          color: theme.palette.white.main,
        }
      }
    }
  };

  // const first_singular_value_psd_chart_data = {
  //   labels: firstSingularPSDValueLabels,
  //   datasets: [
  //     {
  //       label: "First Singular PSD value",
  //       data: firstSingularPSDValueData,
  //       borderColor: "rgb(53, 162, 235)",
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //       pointRadius: 0,
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  
  useEffect(() => {
    setChartData({
      labels: firstSingularPSDValueLabels,
      datasets: [
        {
          label: firstSingularPSDValueData.length ? "First Singular PSD value" : "",
          data: firstSingularPSDValueData,
          borderColor: firstSingularPSDValueData.length ? "rgb(53, 162, 235)" : "rgba(0,0,0,0)",
          backgroundColor: firstSingularPSDValueData.length ? "rgba(53, 162, 235, 0.5)" : "rgba(0,0,0,0)",
          pointRadius: 0,
          borderWidth: 1,
        },
      ],
    });
  }, [firstSingularPSDValueLabels, firstSingularPSDValueData]);
  return (
      <BoxCharts>
        <Box sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 3  
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} >Primo valore singolare della PSD (only admin)</Typography>
        <ZoomControls
          onResetZoom={onResetZoom}
          onZoomPluse={onZoomPluse}
          onZoomMinus={onZoomMinus}
          onPanPluse={onPanPluse}
          onPanMinus={onPanMinus}
        />
        </Box>
        <Line
          height={'100%'}
          options={first_singular_value_psd_config}
          data={chartData}
          ref={chartRef}
        />
    </BoxCharts>
  );
}

export default FirstPSDChart;
