import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import infrastructureData from "../data/infrastructure.json";
import { Box, Button, Divider, Grid, IconButton, MenuItem, SvgIcon, TextField } from "@mui/material";
import { ReactComponent as OilRig0SVG } from "../icon/oil_rig_0.svg";
import { ReactComponent as Building0SVG } from "../icon/building_0.svg";
import { ReactComponent as WindMill0SVG } from "../icon/windmill_0.svg";
import { ReactComponent as Bridge0SVG } from "../icon/bridge_0.svg";
import { useNavigate, useParams } from "react-router-dom";
import { StyledDataGrid, StyledLink, StyledPopover } from "./Theme/styledComponents";
import { useTheme } from "@emotion/react";
import { ControlPoint, Delete, Edit, FiberManualRecord, MoreVert, Search } from "@mui/icons-material";
import InfrastructureDetailComponent from "./InfrastructureDetailComponent";
import SearchBar from "./UI/Searchbar";
import MyDataGrid from "./UI/DataGrid";
import LinkButton from "./UI/LinkButton";





export default function InfrastructureCard() {
  const theme = useTheme();
  const { id, infrastructureId } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [searchedRows, setSearchedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const gridHeight = 70 * (rows.length + 2);

  const renderCellType = (params) => {
    const type = params.value;
    return (
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: grey[800] }} aria-label="recipe">
          {type === "building" && (
            <SvgIcon component={Building0SVG} viewBox="0 0 1200 1200" />
          )}
          {type === "oil_rig" && (
            <SvgIcon component={OilRig0SVG} viewBox="0 0 1200 1200" />
          )}
          {type === "windmill" && (
            <SvgIcon component={WindMill0SVG} viewBox="0 0 1200 1200" />
          )}
          {type === "bridge" && (
            <SvgIcon component={Bridge0SVG} viewBox="0 0 1200 1200" />
          )}
        </Avatar>
        <Box ml={1}>
          {type}
        </Box>
      </Box>
    );
  };

  const renderCellSensorStatus = (params) => {
    const status = params.value;
    return (
      <Box display="flex" alignItems="center">
        <FiberManualRecord
          style={{ color: status === "online" ? 'green' : 'red' }}
        />
        <Box ml={1}>
          {status}
        </Box>
      </Box>
    );
  }

  // Creo le colonne per il DataGrid
  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 150,
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Tipo',
      width: 150,
      flex: 1,
      renderCell: renderCellType,
    },
    {
      field: 'total_sensors',
      headerName: 'Sensori presenti',
      width: 200,
      flex: 1
    },
    {
      field: 'working_sensors',
      headerName: 'Sensori funzionanti',
      width: 200,
      flex: 1
    },
    {
      field: 'sensors_status',
      headerName: 'Stato sensori',
      width: 200,
      flex: 1,
      renderCell: renderCellSensorStatus,
    },
    {
      field: 'errors',
      headerName: 'Numero di errori',
      width: 150,
      flex: 1
    },
    {
      field: 'model_TwoD',
      headerName: 'Immagine ?',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <img src={params.value || "/img/default.png"} alt="image" style={{ width: '80px', height: '52px' }} />
      ),
    },
    {
      field: 'actions',
      headerName: 'Azione',
      width: 100,
      flex: 0.5,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event) => handleClick(event, params.row.id)}
          >
            <MoreVert sx={{ color: theme.palette.white.main }} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClick = (event, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleSearchChange = (event, newValue) => {
    const lowerCaseValue = newValue.toLowerCase();
    setSearchedRows(
      rows.filter((row) =>
        row.name.toLowerCase().includes(lowerCaseValue)
      )
    );
  };

  const handleRowClick = (param, event) => {
    navigate(`/project/${id}/projectInfrastructure/${param.row.id}`);
  };

  useEffect(() => {
    let matchedInfrastructures = infrastructureData.filter(infrastructure => infrastructure.project_id === id);
    setRows(matchedInfrastructures);
    setSearchedRows(matchedInfrastructures); // Imposta anche searchedRows qui
  }, [id]);


  return (
    <Grid item display="flex" flexDirection="column" alignItems="center"
      gap={3}
      sx={{
        width: "100%",
        display: "flex",
      }}>

      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }} >
        <Typography variant='h4' fontWeight={700} >Infrastrutture</Typography>
        <Typography variant='subtitle2' fontWeight={400} >Elenco infrastrutture</Typography>
      </Box>

      <Divider variant="middle" sx={{ width: '100%', marginY: 2 }} />

      <InfrastructureDetailComponent infrastructureId={infrastructureId} />

      <Box sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
      }} >
        <SearchBar
          options={rows.map((row) => row.name)}
          handleSearchChange={handleSearchChange}
          label="Cerca infrastruttura"
          width={300}
          icon={<Search />}
        />

        <LinkButton
          to={`/project/${id}/projectInfrastructure/add`}
          variant="contained"
          startIcon={<ControlPoint />}
        >
          AGGIUNGI INFRASTUTTURA
        </LinkButton>
      </Box>

      <Box sx={{ height: `${gridHeight}px`, width: '100%', marginBottom: 4 }}>
        <MyDataGrid
          columns={columns}
          rows={searchedRows}
          onRowClick={handleRowClick}
          rowHeight={70}
        />
        <StyledPopover
          id={`action-popover-${selectedRow}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            'aria-labelledby': `action-button-${selectedRow}`,
          }}
        >
          <MenuItem
            sx={{ color: 'text.primary' }}
            onClick={handleClose}
            component={StyledLink}
            to={`/project/${id}/projectInfrastructure/${selectedRow}/modifyInfrastructure`}
            disableRipple
          >
            <Edit style={{ color: theme.palette.white.main }} />
            Edit
          </MenuItem>
          <MenuItem
            sx={{ color: 'text.primary' }}
            onClick={handleClose}
          >
            <Delete style={{ color: theme.palette.secondary.main }} />
            Delete
          </MenuItem>
        </StyledPopover>

      </Box>
    </Grid>
  );
}

