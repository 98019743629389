import React, { useEffect, useState } from 'react';
import { AccountCircle, Key, Logout } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { StyledNavLink } from '../Theme/styledComponents';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

function LogOutDetail() {
    const { user } = useAuthenticator((context) => [context.user]);
    const [anchorEl, setAnchorEl] = useState(null);
    const { signOut } = useAuthenticator();
    const navigate = useNavigate();
    const [account, setAccount] = useState("");
    const [role, setRole] = useState("");

    const handleLogout = () => {
        signOut();
        navigate("/");
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const GroupMapping = {
        admin: 'Super Admin',
        superAdmin: 'Admin Azienda',
        adminRead: 'Admin Sola Lettura',
        user: 'Editor User'
    }

    
    useEffect(() => {
        const currentAccount = user?.getSignInUserSession()?.getIdToken();
        setAccount(currentAccount?.payload["email"]);

        setRole(
            GroupMapping[
            currentAccount?.payload["cognito:groups"].length === 1
                && currentAccount?.payload["cognito:groups"][0]    
            ]
        );
    }, [user]);

 
    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        bgcolor: "background.default",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.default",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={handleClose}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography sx={{ mb: 2 }}>
                        <strong>Utente:</strong>
                        {account}
                    </Typography>
                    <Typography>
                        <strong>Ruolo:</strong>
                        {role}
                    </Typography>
                </MenuItem>
                <StyledNavLink
                    to={"/changePassword"}
                    key={"changePassword"}
                    onClick={handleClose}
                >
                    <MenuItem>
                        <b>Cambia password </b> <Key />
                    </MenuItem>
                </StyledNavLink>
                <MenuItem
                    onClick={handleLogout}
                >
                    <b>Logout </b> <Logout />
                </MenuItem>
            </Menu>
        </>
    );
}

export default LogOutDetail;