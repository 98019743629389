import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDataGrid } from "../Theme/styledComponents";
import MyDataGrid from "../UI/DataGrid";

function ModiFDDadmin({ analysisResult }) {
  const [modiFddAdminRows, setModiFddAdminRows] = useState([]);
  const [modiFddAdminColumns, setModiFddAdminColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState("80vw");

  useEffect(() => {
    let modi_fdd_admin_columns = [
      {
        field: "measurement_name",
        headerName: "Name",
        flex: 1,
      },
    ];
    let modi_fdd_admin_rows = [];
    analysisResult.result.frequenze_trovate_FDD.forEach((freq_fdd, index) => {
      modi_fdd_admin_columns.push({
        field: "modi_fdd_admin_" + index,
        headerName: "Modo " + (index + 1),
        flex: 1,
      });
    });
    setModiFddAdminColumns(modi_fdd_admin_columns);
    analysisResult.result.modi_trovati_FDD.forEach((modi_fdd, index) => {
      let row = {
        id: "modi_fdd_admin_" + index,
        measurement_name: "Sens. " + (index + 1),
      };
      modi_fdd.forEach((modo, i) => {
        row["modi_fdd_admin_" + i] = modo;
      });
      modi_fdd_admin_rows.push(row);
    });
    setModiFddAdminRows(modi_fdd_admin_rows);
  }, [analysisResult.result.modi_trovati_FDD, analysisResult.result.frequenze_trovate_FDD]);

  useEffect(() => {
    let totalWidth = 0;
    modiFddAdminColumns.forEach((column) => {
      totalWidth += column.width;
    });
    setTableWidth(totalWidth);
  }, [modiFddAdminColumns]);

  return (
    <>
      <Box>
        <Typography variant='h5' color="text.secondary" fontWeight={600} >
          8. Tabella con i modi selezionati per gli indici di danno - SOLO PER
          SUPER ADMIN
        </Typography>
        <Typography variant='subtitle2' color="text.secondary" fontWeight={400} >
          Lunghezza di frequenze trovate FDD
        </Typography>

      </Box>
      <Box
        id={"IndiciDanno-2-table-admin"}
        sx={{
          height: 700,
          // width: tableWidth || "80vw", 
          // maxWidth: "80vw" 
        }}
      >
        <MyDataGrid
          rows={modiFddAdminRows}
          columns={modiFddAdminColumns}
        />
      </Box>
    </>
  );
}

export default ModiFDDadmin;
