import styled from "@emotion/styled";
import { Button, InputBase, Popover, TextField, alpha } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DateCalendar, DateField, DatePicker } from "@mui/x-date-pickers";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}))

export const StyledNavLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText
}))

export const StyledPopover = styled((props) => (
  <Popover
    elevation={5}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.background.default ,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// export const MyTextField = styled(TextField)(({ theme }) => ({
//   '& label.Mui-focused': {
//     color: theme.palette.white.main,
//   },
//   '& .MuiInputBase-input': {
//     color: theme.palette.white.main,
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset.MuiOutlinedInput-notchedOutline':{
//       borderColor: theme.palette.white.main,
//     },
//     '&:hover fieldset': {
//       borderColor: theme.palette.white.main,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.palette.smokeWhite.main,
//     },
//   },
// }));

export const StyledDataGrid = styled(DataGrid)`
&& {
  & .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
  & .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.main};
    border: 0px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px; 
  }
  & .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.primary.transparentMain};
    border-radius: 5px;
  }
}
.first_row_table {
  background-color: #1F3049 !important;
}
`;

export const GridButton = styled(Button)(({ theme }) => ({
  "&&": {
    backgroundColor: "#1C1B1F",
    color: theme.palette.brightAzure.contrastText,
    border: "1px solid ",
    borderColor: theme.palette.brightAzure.main,
    borderRadius: '8px',
    textTransform: "none",
    "&:hover": {
      borderColor: theme.palette.brightAzure.hover,
    },
  },
}));

export const DateFieldStyled = styled(DateField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.contrastText,
  },
  '& .MuiInputBase-input': {
    color: theme.palette.primary.contrastText,
  },
  //disable <
  '& .Mui-disabled': {
    color: theme.palette.grey.main,
    borderColor: theme.palette.grey.main,
    '& .MuiInputBase-input': {
      color: theme.palette.grey.main,
    },
  },
  // disabled >
  ///////////////////
  // input <
  '& .MuiOutlinedInput-root': {
    '& fieldset.MuiOutlinedInput-notchedOutline':{
      borderColor: theme.palette.primary.contrastText,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  // input >
}));


// export const TextFieldEnd = styled(DateField)(({ theme }) => ({
//   '& label.Mui-focused': {
//     color: theme.palette.primary.contrastText,
//   },
//   '& .MuiInputBase-input': {
//     color: theme.palette.primary.contrastText,
//   },
//   '& .MuiOutlinedInput-root': {
//     '& fieldset.MuiOutlinedInput-notchedOutline':{
//       borderColor: theme.palette.primary.contrastText,
//     },
//     '&:hover fieldset': {
//       borderColor: theme.palette.primary.contrastText,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.palette.primary.contrastText,
//     },
//   },
// }));


export const StartCalendar = styled(DateCalendar)(({theme}) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey.main,
  },
}));

export const EndCalendar = styled(DateCalendar)(({theme}) => ({
  
  //disable text <
  '& .MuiButtonBase-root.Mui-disabled.MuiPickersDay-root': {
    color: theme.palette.grey.main,
  },
  //icon
  '& .MuiButtonBase-root.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey.main,
  },
  // disabled >
}));


