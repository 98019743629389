import React from 'react';
import { Grid, Typography, Button, Divider, List, ListItem, ListItemText, Box, SvgIcon } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { StyledLink } from "../components/Theme/styledComponents";
import analysis from '../data/analysis.json';
import { ReactComponent as deleteIcon } from '../icon/deleteIcon.svg';
import { useTheme } from '@emotion/react';


function AnalysisConfiguration() {

  const theme = useTheme();
  const { id, infrastructureId, configurationId } = useParams();

  const [elementFound, setElementFound] = useState([]);

  useEffect(() => {
    for (let i = 0; i < analysis.length; i++) {
      if (configurationId === analysis[i].id) {
        setElementFound(analysis[i]);
        return;
      };
    };
  }, [configurationId])

  const deleteAnalysis = () => {
    console.log("Analysis deleted")
  }

  return (
    <Grid item xs={12} sm={12} md={12} sx={{ ml: 0, mr: 'auto' }} >
      <Typography variant='h4' fontWeight={700} >Configurazione {elementFound.name ? elementFound.name.replace('_', ' ') : " "}</Typography>
        <Divider  variant='middle' sx={{ width: '100%', mt: 2, mb: 2 }} />
      <Box display='inline-block' sx={{ backgroundColor: "#101010", p: 2 }}>
        <Typography variant="h6" sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Configurazione attuale</Typography>
        <Divider variant='middle' sx={{ paddingY: 2 }} />
        <List>
          <ListItem>
            <ListItemText primary={`Analysis type: ${elementFound.analysis_type}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Field pc id: ${elementFound.field_pc_id}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Schedule type: ${elementFound.schedule_type}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Schedule every seconds: ${elementFound.schedule_every_seconds}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Seconds data to analyze: ${elementFound.seconds_data_to_analyze}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Version: ${elementFound.version}`} primaryTypographyProps={{ color: theme.palette.text.progressBarDone }} />
          </ListItem>
        </List>

        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "space-evenly", mt: 1 }}>
            <Button
              component={StyledLink}
              to={`/project/${id}/projectInfrastructure/${infrastructureId}/modifyAnalysisType/${configurationId}`}
            >
              Modifica
            </Button>
            <Button
              sx={{
                backgroundColor: 'secondary.main',
                '&:hover': {
                  backgroundColor: 'secondary.hover'
                }
              }}
              variant='contained'
              onClick={deleteAnalysis}
              startIcon={<SvgIcon component={deleteIcon} viewBox="0 0 17 17" />}
            >
              Elimina
            </Button>
        </Box>
      </Box>
    </Grid>
  )
}

export default AnalysisConfiguration;
