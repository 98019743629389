import { alpha, createTheme } from "@mui/material";
import { itIT } from "@mui/x-date-pickers/locales";

//orange: #F07F31
//grey-fieldset: #494B4D
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1F2A3B',
      light: '#4B5462',
      hover: '#3e4f69',
      breadcrumbs: '#3D5374',
      transparentMain: 'rgba(0, 0, 0, 0)',
      contrastText: "#f0f0f0",
    },
    white: {
      main: "#F3F6F8",
      dark: "#F3F6F8",
      contrastText: "#1F2A3B",
    },
    whiteCalendar: {
      main: "F0F0F0",
      contrastText: "#381E72"
    },
    smokeWhite: {
      main: "#DADEE0",
      dark: "#DADEE0",
      contrastText: "#1F2A3B",
    },
    secondary: {
      main: "#f50057",
      hover: "#ed6898"
    },
    success: {
      main: "#347B41",
    },
    orange: {
      main: "#F07F31",
      blurred: "#674B37",
      hover: "#e39966"
    },
    brightAzure: {
      main: "#71A9FD",
      contrastText: "#FFFFFF",
      hover: '#98bffa'
    },
    blue: {
      main: "#4094F7",
      progress: "#2872E1",
      blurred: "#22406C",
      hover: "#71A9FD"
    },
    grey: {
      main: "#7C7883",
      transparent: "#d2d2d2e6"
    },
    gradient: {
      main: "linear-gradient(180deg, #2C2D33 0%, #252A5E 100%)"
    },
    background: {
      default: "#171719",
      paper: "#171719",
      calendar: "#24212A",
    },
    divider: '#59595959',
    text: {
      primary: '#F0F0F0',
      ///anche per week calendar
      secondary: '#828282',
      // secondary: '#64748B',
      black: "#252C32",
      progressBarDone: "#BED3F3"
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body2: {
      fontSize: 15,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#F0F0F0',
          "&.Mui-focused": {
            color: "#fff"
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: '#7C7883',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#494B4D',
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F3F6F8"
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F3F6F8"
          },
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: "transparent",
        },
        withBorderColor: {
          borderColor: "transparent"
        },
        cell: {
          "&:focus, &:focus-within": {
            outlineColor: 'rgba(0,0,0,0)',
          },
          borderColor: "#4F4F4F !important",
        },
        columnHeader: {
          "&:focus, &:focus-within": {
            outlineColor: 'rgba(0,0,0,0)',
          },
        },
        iconSeparator: {
          color: "#F0F0F075"
        },
        row: {
          borderColor: "red"
        }
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          display: "none !important"
        },
        selectIcon: {
          color: "#F0F0F0"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":disabled": {
            color: "#7C7883"
          }
        },
        sizeSmall: {
          color: "#f0f0f0"
        },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#F0F0F0',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderColor: '#71A9FD',
          color: '#71A9FD',
        },
        outlined: {
          backgroundColor: '#1C1B1F',
        },
        colorPrimary: {
          borderColor: "#fff",
          color: "#fff",
        }
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#2872E1',
          '&:hover': {
            backgroundColor: '#71A9FD',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: "#F6F8F9",
          backgroundColor: '#2872E1',
          '&:hover': {
            backgroundColor: '#71A9FD',
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          color: "#F0F0F0"
        }
      }
    },
    MuiPickersYear: {
          styleOverrides: {
          yearButton:{
            '&.Mui-selected': {
              backgroundColor: '#2872E1',
              '&:focus': {
                backgroundColor: '#2872E1'
              }
            },
          }
        }
    },
    MuiPickersMonth: {
          styleOverrides: {
          monthButton:{
            '&.Mui-selected': {
              backgroundColor: '#2872E1',
              '&:focus': {
                backgroundColor: '#2872E1'
              }
            },
          }
        }
    }
  },
},
);


