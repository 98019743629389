import { AccountSettings, ThemeProvider } from '@aws-amplify/ui-react';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const theme = {
    name: 'passwordfield-theme',
    tokens: {
      components: {
        button: {
            color: {value: "orange"}, 
            _hover: {
                backgroundColor: {value: 'transparent'},
                color: {value: 'pink' },
                borderColor: {value: 'green'}
            },
        },
        passwordfield: {
          color: { value: 'blue' }, // colore bordi passwordfield
          button: {
            color: { value: 'red' }, // Colore icona occhio
            borderColor: {value: "white"},
            _hover: {
              backgroundColor: { value: 'blue' }, /// bg color hover
              borderColor: { value: 'blue' }, // bordercolor quando è hovered
              color: { value: 'red' }, // color button interno hover
            },
            _focus: {
              backgroundColor: { value: 'purple' },
              borderColor: { value: 'purple' }, //  borderColor pulsante quando è focused
              color: { value: 'orange' }, // occhio color quando è focused
            },
          },
        },
        fieldcontrol: {
          borderColor: { value: 'orange' }, // colore bordo button occhio
          color: { value: '#f0f0f0' }, /// colore interno testo
        },
        textfield: {
            _focus: {
              borderColor: { value: '{colors.blue.40}' }, // colore focus bordo testo
            },
          },
          field: {
            label:{
                color: {value: 'red'} // colore label
            }
          }
      },
    },
  };
  

function HomePage() {


    return (
        <Grid container>
            <Box padding={12} >
                <h3 >Contenuto Home Page</h3>
                <Typography variant='body2'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti blanditiis ab, beatae a ex odio optio repellendus esse rem similique, deserunt pariatur nesciunt, dolor hic. Officia fuga doloribus a deleniti?
                    Quaerat porro doloribus voluptate, ratione corrupti harum quibusdam atque quia aliquam minus facere eum laborum delectus molestias earum? Reiciendis porro quaerat nam necessitatibus commodi voluptas dolores quos sequi odio quas.
                </Typography>
            </Box>
            <Box>
                {/* <ThemeProvider theme={theme}>
                <AccountSettings.ChangePassword/>
                </ThemeProvider> */}
            </Box>
        </Grid>
    );
}

export default HomePage;