import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

const useDetailedPermission = (requiredGroups = [], requireAll = true) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    checkAuthorization();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthorization = () => {
    if (!user || requiredGroups.length === 0) {
      setAuthorized(false);
      return;
    }

    const currentAccount = user?.getSignInUserSession()?.getIdToken();
    const userGroups = currentAccount?.payload["cognito:groups"] || [];

    const isUserAuthorized = requireAll 
      ? requiredGroups.every((group) => userGroups.includes(group))
      : requiredGroups.some((group) => userGroups.includes(group));

    setAuthorized(isUserAuthorized);
  };

  return authorized;
};

export default useDetailedPermission;


//use
// const authorized = useAuthorization(["admin", "editor"], true);      appartenere a tutti e due i gruppi

// const authorized = useAuthorization(["admin", "editor"], false);     appartenere ad almeno un gruppo
