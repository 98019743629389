import infrastructureData from '../data/infrastructure.json'
import projects from '../data/projects.json'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, ButtonGroup, Collapse, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { StyledLink } from './Theme/styledComponents'
import React, { useState, useEffect } from "react"
// import {Suspense, useRef} from "react"
// import { ContactShadows, Environment, OrbitControls, useGLTF } from "@react-three/drei"
// import { Canvas } from "@react-three/fiber"
import InfrastructureElementDetails from "./InfrastructureElementDetails";
import HotelModel from "./3D_Models/HotelModel";
import Bridge1Model from "./3D_Models/Bridge1Model";
import Windmill1Model from "./3D_Models/Windmill1Model";
import Oilrig1Model from "./3D_Models/OilRig1Model";
import { useTheme } from '@emotion/react';
import { Close, ExpandMore, HelpOutline } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import LinkButton from './UI/LinkButton'

export function Render3DModel(props) {
    return (
        <>
            {
                props.model_threeD && props.model_threeD === "Hotel.glb" && <HotelModel setElementSelected={props.setElementSelected} />
            }
            {
                props.model_threeD && props.model_threeD === "bridge_1.glb" && <Bridge1Model setElementSelected={props.setElementSelected} />
            }
            {
                props.model_threeD && props.model_threeD === "windmill_1.glb" && <Windmill1Model setElementSelected={props.setElementSelected} />
            }
            {
                props.model_threeD && props.model_threeD === "oilrig_1.glb" && <Oilrig1Model setElementSelected={props.setElementSelected} />
            }
        </>
    )
}
function InfrastructureDetailComponent() {
    const theme = useTheme();
    const { id, infrastructureId } = useParams();
    const [project, setProject] = useState();
    const [infrastructure, setInfrastructure] = useState();
    const [elementSelected, setElementSelected] = useState();
    const [twoDview, setTwoDview] = useState(true);
    const [threeDview, setThreeDview] = useState(false);
    const [selectedBtn, setSelectedBtn] = useState(2);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setInfrastructure(infrastructureData.filter(inf => inf["id"] === infrastructureId)[0])
    }, [infrastructureId])

    useEffect(() => {
        if (infrastructure) {
            setProject(projects.filter(project => project["id"] === infrastructure.project_id)[0])
        }
    }, [infrastructure])


    const handleChangeModalView = () => {
        if (twoDview === true) {
            setTwoDview(false);
            setThreeDview(true);
            setSelectedBtn(1);
        }
        else {
            setSelectedBtn(2);
            setTwoDview(true);
            setThreeDview(false);
        }
    }



    return (

        (infrastructure && project) &&
        <>
            <Grid
                item
                display="flex"
                gap={3}
                sx={{
                    flexDirection: { sm: "column", md: "column", lg: "row" },
                    width: "100%",
                    backgroundColor: "primary.main",
                    marginBottom: 3,
                    px: 8,
                    py: 2
                }}>

                <Grid
                    item
                    sm={12} md={12} lg={6}
                    sx={{ maxHeight: "600px" }}>  {/* Div sinistro con id "foto" */}

                    {twoDview &&
                        <Box id="foto" sx={{ width: "90%", height: "100%", margin: "0 auto" }}>
                            <img src={infrastructure.model_TwoD} alt='' style={{ width: "100%", height: "100%" }} />
                        </Box>
                    }

                    {threeDview &&
                        // <Grid item md={12} lg={6}>  {/* Div sinistro con id "foto 3D" */}
                        <Box id="model3d" sx={{ width: "100%", height: "600px" }}>
                            <Render3DModel
                                model_threeD={infrastructure.model_threeD}
                                setElementSelected={setElementSelected}
                            />
                        </Box>
                    }
                </Grid>

                <Grid item md={12} lg={6}  >  {/* Div centrale con id "dettagli-principali" */}
                    <Box sx={{
                        display: "inline-block",
                        backgroundColor: "white",
                        color: theme.palette.primary.main,
                        borderRadius: 1,
                        mb: 1,
                        p: 1
                    }}>
                        Bologna
                    </Box>
                    <Typography variant='h4' fontWeight={700} >{infrastructure.name}</Typography>
                    <Accordion
                        elevation={0}
                        sx={{
                            backgroundColor: "primary.main",
                            '&::before': {
                                content: 'none',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: "whiteSmoke.main" }} />}
                            sx={{
                                BorderTop: "none",
                                flexDirection: "row-reverse"
                            }}
                        >
                            <Typography sx={{ whiteSpace: "nowrap", pl: 1 }}>Dettagli Principali</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box id="general_details" sx={{ display: "flex", flexDirection: "column" }} >
                                <Box>
                                    Numero di sensori: {infrastructure.total_sensors}
                                </Box>
                                <Box>
                                    Sensori funzionanti: {infrastructure.working_sensors}
                                </Box>
                                <Box>
                                    Status sensori: {infrastructure.sensors_status}
                                </Box>
                                <Box>
                                    Errori: {infrastructure.errors}
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Divider variant='middle' />

                    {elementSelected &&
                        <Accordion
                            elevation={0}
                            sx={{
                                backgroundColor: "primary.main",
                                '&::before': {
                                    content: 'none',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore sx={{ color: "whiteSmoke.main" }} />}
                                sx={{
                                    flexDirection: "row-reverse"
                                }}
                            >
                                <Typography sx={{ whiteSpace: "nowrap", pl: 1 }}>Dettagli Sensori 3D</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box id="dettagli" >
                                    <InfrastructureElementDetails elementSelected={elementSelected} />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {threeDview &&
                        <>
                            <Box sx={{ width: '100%' }}>
                                <Collapse in={open}>
                                    <Alert
                                        sx={{
                                            backgroundColor: '#353535',
                                            borderRadius: '4px',
                                            mb: 2
                                        }}
                                        icon={false}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <Close fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        <Box sx={{ color: "primary.contrastText" }}>
                                            In the 3D view, the
                                            <Box component="span" sx={{ color: 'green' }}> green</Box>,
                                            <Box component="span" sx={{ color: 'red' }}> red</Box> and
                                            <Box component="span" sx={{ color: 'orange' }}> orange</Box> dots are interactive. Click on them for more info.
                                        </Box>
                                    </Alert>
                                </Collapse>
                                {!open && (
                                    <IconButton
                                        aria-label="help"
                                        size="small"
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        <HelpOutline sx={{ color: "whiteSmoke", pl: 1 }} />
                                    </IconButton>
                                )}
                            </Box>
                        </>
                    }


                    <Stack direction="column"  spacing={3} sx={{ mt: 2, width: '100%' }} >
                        <LinkButton
                            to={`analysisType`}
                            fullWidth
                            variant="contained"
                        >
                            Analisi svolte
                        </LinkButton>
                        <LinkButton
                            to={`modifyInfrastructure`}
                            fullWidth
                            sx={{
                                backgroundColor: "#00000000",
                                '&:hover': {
                                    backgroundColor: 'primary.hover',
                                    borderColor: 'primary.hover'
                                }
                            }}
                            color="white"
                            variant="outlined"
                        >
                            Modifica infrastruttura
                        </LinkButton>
                        </Stack>
                        <Stack alignItems={'flex-start'} sx={{mt:3}}>
                        <ButtonGroup variant="contained" size='small' >
                            <Button
                                sx={{
                                    backgroundColor: selectedBtn === 1 ? "blue.progress" : "blue.progress",
                                    '&:hover': {
                                        backgroundColor: "blue.hover"
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#7C7883'
                                    },
                                }}
                                onClick={handleChangeModalView}
                                disableRipple
                                disabled={selectedBtn === 2}
                            >
                                2D
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: selectedBtn === 2 ? "blue.progress" : "blue.progress",
                                    '&:hover': {
                                        backgroundColor: "blue.hover"
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#7C7883'
                                    },
                                }}
                                onClick={handleChangeModalView}
                                disableRipple
                                disabled={selectedBtn === 1}

                            >
                                3D
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Grid>
            </Grid>

            <Divider variant='middle' sx={{ width: "100%", mt: 2, mb: 2 }} />
        </>

    )
}

export default InfrastructureDetailComponent
