import React, {useState, useEffect} from 'react'

import analysisJson from '../data/analysis.json'
import analysisResultsJson from '../data/analysis_result_example.json'

import {useParams} from 'react-router-dom';
import ModalAnalysisDetail from "./ModalAnalysisDetail";
import { Grid } from '@mui/material';

function AnalysisDetail() {
    const {resultsId} = useParams();
    const [analysis, setAnalysis] = useState({})
    const [resultAnalysisData, setResultAnalysisData] = useState({})

    useEffect(() => {
        // Fetch related analysisResult
        for (let i = 0; i < analysisResultsJson.length; i++) {
            if (resultsId === analysisResultsJson[i].id) {
                console.log("setResultAnalysisData")
                console.log(analysisResultsJson[i])
                setResultAnalysisData(analysisResultsJson[i])
                break
            }
        }
    }, [resultsId])
    
    useEffect(() => {
        // Fetch related analysis
        for (let i = 0; i < analysisJson.length; i++) {
            if (resultAnalysisData.analisi_id === analysisJson[i].id) {
                console.log("setAnalysis")
                console.log(analysisJson[i])
                setAnalysis(analysisJson[i])
                break
            }
        }
    }, [resultAnalysisData])

    return (
        <>
        <Grid
            item
            sx={{ width: "100%" }}
        >
            {
                analysis && analysis.analysis_type === "soglie" && <div>analisi soglie</div>
            }
            {
                analysis && analysis.analysis_type === "modale" && <ModalAnalysisDetail analysisResult={resultAnalysisData}/>
            }
        </Grid>
        </>
    )
}

export default AnalysisDetail
