import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDataGrid } from "../Theme/styledComponents";
import MyDataGrid from "../UI/DataGrid";

function TabellaFDD({ analysisResult }) {
  const [fddRows, setFddRows] = useState([]);
  const [fddColumns, setFddColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState("80vw");

  useEffect(() => {
    let fdd_columns = [
      {
        field: "measurement_name",
        headerName: "Name",
        flex: 1,
      },
    ];
    let fdd_first_row = {
      id: "fdd_first_row",
      measurement_name: "FDD",
    };

    analysisResult.result.freq_FDD.forEach((fdd, index) => {
      fdd_columns.push({
        field: "fdd_" + index,
        headerName: "Modo " + (index + 1),
        flex: 1,
      });
      fdd_first_row["fdd_" + index] = fdd;
    });
    setFddColumns(fdd_columns);
    let fdd_rows = [];
    fdd_rows.push(fdd_first_row);
    // Metto i modi_FDD
    analysisResult.result.modi_FDD.forEach((modi_fdd, index) => {
      let row = {
        id: "fdd-row-" + index,
        measurement_name: "Sens. " + (index + 1),
      };
      modi_fdd.forEach((modo, i) => {
        row["fdd_" + i] = modo;
      });
      fdd_rows.push(row);
    });
    setFddRows(fdd_rows);
  }, [analysisResult]);

  useEffect(() => {
    let totalWidth = 0;
    fddColumns.forEach((column) => {
      totalWidth += column.width;
    });
    setTableWidth(totalWidth);
  }, [fddColumns]);

  return (
    <>
      <Box>
        <Typography variant='h5' color="text.secondary" fontWeight={600} >
          3. Tabella con i modi identificati dal metodo **FDD** (Frequency Domanin
          Decomposition)
        </Typography>
      </Box>
      
        <Box
          id={"FDD-table"}
          sx={{ 
            height: 700,
            // width: tableWidth || "80vw",
            // maxWidth: "80vw"
          }}
        >
          <MyDataGrid
            rows={fddRows}
            columns={fddColumns}
            getCellClassName={(params) => {
              if (params.id === "fdd_first_row") {
                return "first_row_table";
              }
              return "";
            }}
          />
        </Box>
    </>
  );
}

export default TabellaFDD;
