import { Box, Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { useMatches } from 'react-router-dom';
import { StyledLink } from '../Theme/styledComponents';
import { useTheme } from '@emotion/react';

function CustomBreadcrumbs() {
  const theme = useTheme();
  let matches = useMatches();
  // console.log("matches: ", matches)

  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));

  return (
    <Box sx={{
      display: "flex",
      alignItems:"center",
      marginTop: 5,
      paddingX:10,
      paddingY:2,
      backgroundColor: theme.palette.primary.breadcrumbs,
    }}>
      <Breadcrumbs
        separator={<span style={{ color: theme.palette.primary.contrastText }}>&#x2022;</span>}
        aria-label="breadcrumb"
      >
        <StyledLink to="/">
          <Typography variant='caption' color="text.primary">
            Home
            </Typography>  
        </StyledLink>
        {crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1;
          return (
            <span key={index}>
                {isLast ? (
                    <Typography variant='caption' color="text.primary">
                        {crumb}
                    </Typography>
                ) : (
                    crumb
                )}
            </span>
        );
        })}
      </Breadcrumbs>
    </Box>
  );
}

export default CustomBreadcrumbs;
