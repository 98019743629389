import { Chip, Divider, Grid } from '@mui/material';
import {ScrollTo} from './ScrollTo';
import { useUserAttributes } from '../Auth/Hooks/UserDetails';
import { useState } from 'react';
import { useMyScroll } from '../../Context/ScrollProvider';

// const chipConfig = {
//   admin: ["First PSD", "Tabella SSI", "Tabella FDD", "Tabella SSI - admin", "Tabella FDD - admin", "Modi SSI - admin", "Modi FDD - admin"],
//   default: ["Tabella SSI", "Tabella FDD"],
// };

const UserChips = ({chipConfig}) => {
  
  const { role } = useUserAttributes();
  const authorizedChips = role.includes('admin') ? chipConfig.admin : chipConfig.default;
  const {activeChip, setActiveChip} = useMyScroll();
  // const [activeChip, setActiveChip] = useState(null);

  return (
    <>
    <Grid container item spacing={2} wrap="wrap">
      {authorizedChips.map(table => (
        <Grid item key={table}>
          <ScrollTo to={table} offset={-70}>
            <Chip 
                label={`${table}`} 
                variant="outlined" 
                onClick={() => setActiveChip(table)}
                color={table === activeChip ? "primary" : "default"}
                sx={{ cursor: 'pointer' }}
                />
          </ScrollTo>
        </Grid>
      ))}
    </Grid>
    </>
  );
}

export default UserChips;
