import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { StyledLink, StyledPopover } from "../components/Theme/styledComponents";
import { Grid, IconButton, Box, MenuItem, Typography, Divider } from "@mui/material";
import { ControlPoint, Delete, Edit, MoreVert, Search } from "@mui/icons-material";
import useDetailedPermission from "../components/Auth/Hooks/SpecificAuthorizationHook";

import projects from "../data/projects.json";
import PopoverCellComponent from "../components/UI/PopoverCellComponent";
import { useTheme } from "@emotion/react";
import SearchBar from "../components/UI/Searchbar";
import MyDataGrid from "../components/UI/DataGrid";
import LinkButton from "../components/UI/LinkButton";

const columnsDefinition = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.5,
    minWidth: 50,
    maxWidth: 70,
  },
  {
    field: 'name',
    headerName: 'Nome',
    flex: 1,
  },
  {
    field: 'region',
    headerName: 'Regione',
    flex: 1,
  },
  {
    field: 'infrastructuresNumber',
    headerName: 'Infrastrutture',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'Azione',
    flex: 0.5,
    hideable: false, // rende la colonna non swichabbile nel menage column
    filterable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
  },
];


function ListProjects() {

  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  // const authorized = useDetailedPermission(["admin", "editor"], false); //almeno uno
  const authorized = useDetailedPermission(["admin"], true); //tutti e due per forza

  const [searchedProjects, setSearchedProjects] = useState(projects); // con array progetti
  // const [progetti, setProgetti] = useState(projects); // con array progetti
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);



  const columns = columnsDefinition.map(column => {
    const base = {
        ...column,
        renderHeader: () => (
            <PopoverCellComponent value={column.headerName} />
        ),
    };

    if (column.field === 'actions') {
        return {
            ...base,
            renderCell: (params) => (
                <IconButton
                    onClick={(event) => handleClick(event, params.row.id)}
                >
                    <MoreVert sx={{ color: 'white.main' }} />
                </IconButton>
            ),
        };
    } 
    else {
        return {
            ...base,
            renderCell: (params) => params.value,
        };
    }
});

  


  useEffect(() => {
    if (id) {
      const foundProject = projects.find((project) => project["id"] === id);
      if (foundProject) {
        setSearchedProjects([foundProject]);
      }
    } else {
      setSearchedProjects(projects);
    }
  }, [id]);


  const handleSearchChange = (event, newValue) => {
    const lowerCaseValue = newValue.toLowerCase();
    setSearchedProjects(
      projects.filter((project) =>
        project.name.toLowerCase().includes(lowerCaseValue)
      )
    );
  };

  const handleRowClick = (param, event) => {
    navigate(`/project/${param.row.id}/projectInfrastructure`);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };



  return (
    <>
      <Grid item
        gap={3}
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{width: "100%"}} >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1
          }} >
          <Typography variant='h4' fontWeight={700} >Progetti</Typography>
          <Typography variant='subtitle2' fontWeight={400} >Elenco progetti</Typography>
        </Box>

        <Divider variant="middle" sx={{ width: '100%', marginY: 2  }} />

        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        }} >
          <SearchBar
            options={projects.map((option) => option.name)}
            handleSearchChange={handleSearchChange}
            label="Cerca progetto"
            width={300}
            icon={<Search />}
          />

          {authorized &&
            <LinkButton 
              to={"/addProject"} 
              variant="contained" 
              startIcon={<ControlPoint />} 
            >
            AGGIUNGI PROGETTO
          </LinkButton>
            }
        </Box>
        <Box sx={{ height: 400, width: "100%", marginBottom: 4 }}>
          <MyDataGrid
            rows={searchedProjects}
            columns={columns}
            onRowClick={handleRowClick}
            rowHeight={62}        
          />
          <StyledPopover
            id={`action-popover-${selectedRow}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              'aria-labelledby': `action-button-${selectedRow}`,
            }}
          >
            <MenuItem
              sx={{ color: 'text.primary' }}
              onClick={handleClose}
              component={StyledLink}
              to={`/project/${selectedRow}/modifyProject`}
              disableRipple
            >
              <Edit style={{ color: theme.palette.white.main }} />
              Edit
            </MenuItem>
            <MenuItem
              sx={{ color: 'text.primary' }}
              onClick={handleClose}
            >
              <Delete style={{ color: theme.palette.secondary.main }} />
              Delete
            </MenuItem>
          </StyledPopover>
        </Box>
      </Grid>

    </>
  );
}

export default ListProjects;
