import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import { StyledLink } from "./Theme/styledComponents";
import LinkButton from "./UI/LinkButton";

    const sensorDetails = {
        "connection": {
            "type": "connection",
            "name": "Ethercat Connection",
            "status": "Good"
        },
        "building": {
            "type": "building",
            "name": "Civ. 14-16",
            "status": "Good"
        },
        "plc": {
            "type": "plc",
            "name": "PLC",
            "status": "Operative",
            "last_update": "",
            "error": ""
        },
        "s1": {
            "type": "acc_sensor",
            "name": "Sensor 1",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s2": {
            "type": "acc_sensor",
            "name": "Sensor 2",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s3": {
            "type": "acc_sensor",
            "name": "Sensor 3",
            "status": "Offline",
            "last_update": "",
            "last_data": {},
            "error": "Sensor not connected"
        },
        "s4": {
            "type": "acc_sensor",
            "name": "Sensor 4",
            "status": "Offline",
            "last_update": "",
            "last_data": {},
            "error": "Sensor not connected"
        },
        "s5": {
            "type": "acc_sensor",
            "name": "Sensor 5",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s6": {
            "type": "acc_sensor",
            "name": "Sensor 6",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s7": {
            "type": "acc_sensor",
            "name": "Sensor 7",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s8": {
            "type": "acc_sensor",
            "name": "Sensor 8",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s9": {
            "type": "acc_sensor",
            "name": "Sensor 9",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s10": {
            "type": "acc_sensor",
            "name": "Sensor 10",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s11": {
            "type": "acc_sensor",
            "name": "Sensor 11",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },
        "s12": {
            "type": "acc_sensor",
            "name": "Sensor 12",
            "status": "Operative",
            "last_update": "",
            "last_data": {
                "x": 0.54,
                "y": 1.01,
                "z": 0.6,
            },
            "error": ""
        },

    }

function InfrastructureElementDetails({elementSelected}) {
    const [component, setComponent] = useState({})
    useEffect(() => {
        if(["red", "darkyellow", "orange", "glass", "gray", "yellow"].includes(elementSelected)) {
            elementSelected = "building"
        }
        setComponent(sensorDetails[elementSelected])
    }, [elementSelected])

    return (
        <div style={{marginTop: -20}}>
            {component && <>
                <h4>Dettagli:</h4>
                <div>Elemento selezionato: {component["name"]}</div>
                {
                    component["type"] === "building" && <div>
                        <div>Status: {component["status"]}</div>
                        <div style={{paddingTop: 10}}>
                                <LinkButton 
                                    to={'#'}
                                    variant="outlined" >Configura PLC
                                </LinkButton>
                        </div>
                        <div style={{paddingTop: 10}}>
                                <LinkButton 
                                    to={'#'}
                                    variant="outlined" >
                                        Configura Sensori
                                </LinkButton>
                        </div>
                    </div>
                }
                {
                    component["type"] === "plc" && <div>
                        <div>Status: {component["status"]}</div>
                        <div>Last Update: {component["last_update"]}</div>
                        <div>Error: {component["error"]}</div>
                        <div style={{paddingTop: 10}}>
                                <LinkButton 
                                    to={'#'}
                                    variant="outlined" >
                                        Configura PLC
                                </LinkButton>
                        </div>
                    </div>
                }
                {
                    component["type"] === "acc_sensor" && <div>
                        <div>Status: {component["status"]}</div>
                        <div>Last Update: {component["last_update"]}</div>
                        {
                            component["error"] === {} && <div>Last Data: x: {component["last_data"]["x"]}, y: {component["last_data"]["y"]}, x: {component["last_data"]["y"]}, </div>
                        }
                        <div>Error: {component["error"]}</div>
                        <div style={{paddingTop: 10}}>
                                <LinkButton 
                                    to={'#'} 
                                    variant="outlined" >
                                        Configura Sensore
                                </LinkButton>
                        </div>
                    </div>
                }
                {
                    component["type"] === "connection" && <div>
                        <div>Status: {component["status"]}</div>
                    </div>
                }
            </>
            }
        </div>
    )
}

export default InfrastructureElementDetails;
