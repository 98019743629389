import React from 'react';
// import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAuthorization } from './Hooks/DynamicAuthorizationHook';

const WaitingForLoading = () => {
  return <div>Attendi il caricamento</div>;
};

export const RouteProtect = ({ component: Component, groups = [], ...props }) => {
  // const { user } = useAuthenticator((context) => [context.user]);
  const authorized = useAuthorization(groups);

  return (
    <>
      {authorized ? <Component {...props} /> : <WaitingForLoading />}
    </>
  );
};

export default RouteProtect;
