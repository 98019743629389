import {Environment, OrbitControls, useGLTF} from "@react-three/drei";
import React, {Suspense, useRef, useState} from "react";
import {Canvas} from "@react-three/fiber";
import { Button } from "@mui/material";
useGLTF.preload('/3d_model/Hotel.glb')
export default function HotelModel(props) {
    const { nodes, materials } = useGLTF('/3d_model/Hotel.glb')
    const ref = useRef()
    const [orbitControls, setOrbitControls] = useState(null);

    function handleResetOrbit() {
        if (orbitControls) {
          orbitControls.target.set(0, 0, 0);
          orbitControls.object.position.set(40, 0, 0);
          orbitControls.update();
        }
      }
    return (
        <>
        {/* <Button onClick={handleResetOrbit} variant="contained" color="primary" size="small">Reset Orbit</Button> */}
        <Canvas shadows camera={{ position: [40, 0, 0], fov: 80 }}>
            <ambientLight intensity={0.7} />
            {/*<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />*/}
            <Suspense fallback={null}>
                <group
                    {...props}
                    dispose={null}
                    ref={ref}
                    scale={[1.4,1.4,1.4]} //grandezza modello
                    position={[0,-15,0]} //posizione modello 
                    onClick={(e) => {
                        e.stopPropagation()
                        props.setElementSelected(e.object.material.name)
                    }}
                >
                    <mesh geometry={nodes.hotel_cube.geometry} material={materials.red} material-color={"#6e6c6c"}/>
                    <mesh geometry={nodes.hotel_cube_1.geometry} material={materials.darkyellow} material-color={"#f8d0ab"}/>
                    <mesh geometry={nodes.hotel_cube_2.geometry} material={materials.orange} />
                    <mesh geometry={nodes.hotel_cube_3.geometry} material={materials.glass} />
                    <mesh geometry={nodes.hotel_cube_4.geometry} material={materials.gray} />
                    <mesh geometry={nodes.PLC.geometry} material={materials.plc} position={[-0.52, 24.51, 0]} scale={[1, 1, 0.5]} />
                    <mesh geometry={nodes.s1.geometry} material={materials.s1} position={[-0.7, 20.58, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#4ded0e"}/>
                    <mesh geometry={nodes.s2.geometry} material={materials.s2} position={[-0.7, 17.34, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#4ded0e"}/>
                    <mesh geometry={nodes.s3.geometry} material={materials.s3} position={[-0.7, 14.06, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#ed0e0e"}/>
                    <mesh geometry={nodes.s4.geometry} material={materials.s4} position={[-0.7, 10.74, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#ed0e0e"}/>
                    <mesh geometry={nodes.s5.geometry} material={materials.s5} position={[-0.7, 7.5, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#4ded0e"}/>
                    <mesh geometry={nodes.s6.geometry} material={materials.s6} position={[-0.7, 3.66, 8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} material-color={"#4ded0e"}/>
                    <mesh geometry={nodes.s7.geometry} material={materials.s7} position={[-0.7, 20.58, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.s8.geometry} material={materials.s8} position={[-0.7, 17.34, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.s9.geometry} material={materials.s9} position={[-0.7, 14.06, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.s10.geometry} material={materials.s10} position={[-0.7, 10.74, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.s11.geometry} material={materials.s11} position={[-0.7, 7.5, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.s12.geometry} material={materials.s12} position={[-0.7, 3.66, -8.86]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[1, 0.1, 1]} />
                    <mesh geometry={nodes.conn_top.geometry} material={materials.connection} position={[-0.7, 24.71, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[20.13, 1, 1]} />
                    <mesh geometry={nodes.conn_left.geometry} material={materials.connection} position={[-0.7, 14.15, 8.85]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[23.73, 1, 1]} />
                    <mesh geometry={nodes.conn_right.geometry} material={materials.connection} position={[-0.7, 14.21, -8.82]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[23.73, 1, 1]} />
                </group>
                <Environment preset="city" />
                {/*<ContactShadows position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />*/}
            </Suspense>
            {/*<OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />*/}
            <OrbitControls maxPolarAngle={Math.PI / 2} minDistance={30} maxDistance={60} enableZoom={true} enablePan={false} ref={(controls) => setOrbitControls(controls)} />
        </Canvas>
        </>
    )
}
