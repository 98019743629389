import { useTheme } from '@emotion/react';
import { ArrowUpward } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { useMyScroll } from '../../Context/ScrollProvider';

export  function ScrollTo({ to, offset, componentProps, children }) {
    return (
            <LinkScroll
                activeClass="active"
                to={to}
                spy={true}
                smooth={true}
                offset={offset}
                duration={300}
                {...componentProps}
            >
                {children}
            </LinkScroll>
    );
}

export function ScrollTopButton() {
    const [isVisible, setIsVisible] = useState(false);
    const {resetActiveChip} = useMyScroll();

    const toggleVisibility = () => {
      if (window.scrollY > 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
  
    return (
      isVisible &&
      <Box 
        sx={{
            position: 'fixed', 
            bottom: '10%', 
            right: '1%', 
            zIndex: 999
    }}>
      <LinkScroll
        to="top"
        spy={true}
        smooth={true}
        offset={-70}
        duration={300}
        onSetActive={resetActiveChip}
      >
        <Fab size="small" aria-label="scroll back to top" >
          <ArrowUpward />
        </Fab>
      </LinkScroll>
      </Box>
    );
  }