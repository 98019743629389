import React, { useState, useEffect, useRef } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import ChartXRange from "../components/Charts/ChartXRange";
import PSDChart from "../components/Charts/PSDChart";
import FirstPSDChart from "../components/Charts/FirstPSDChart";
import TabellaSSI from "../components/Tables/StocastaticSubspaceIdent";
import TabellaFDD from "../components/Tables/FrequencyDomainDecom";
import TabellaSSIadmin from "../components/Tables/StocastaticSubspaceIdentADMIN";
import TabellaFDDadmin from "../components/Tables/FrequencyDomainDecomADMIN";
import ModiSSIadmin from "../components/Tables/ModiSsiADMIN";
import ModiFDDadmin from "../components/Tables/ModiFddADMIN";
import { chartZoomOptions, createChartRefFunctions } from "../components/UI/ChartZoomControls";
import IndexDamageRadar from "../components/Charts/IndexDamageRadar";
import { useUserAttributes } from "../components/Auth/Hooks/UserDetails";
import { Element } from "react-scroll";
import UserChips from "../components/UI/TableChips";
import { ScrollTopButton } from "../components/UI/ScrollTo";
import { ScrollProvider } from "../Context/ScrollProvider";

const psd_color_map = [
  {
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
  {
    borderColor: "rgb(53,162,135)",
    backgroundColor: "rgba(53,162,135,0.5)",
  },
  {
    borderColor: "rgb(153,162,235)",
    backgroundColor: "rgba(153,162,235,0.5)",
  },
  {
    borderColor: "rgb(53,62,235)",
    backgroundColor: "rgba(53,62,235,0.5)",
  },
  {
    borderColor: "rgb(11,99,36)",
    backgroundColor: "rgba(11, 99, 36, 0.5)",
  },
  {
    borderColor: "rgb(188,24,232)",
    backgroundColor: "rgba(188, 24, 232, 0.5)",
  },
  {
    borderColor: "rgb(231,22,116)",
    backgroundColor: "rgba(231, 22, 116, 0.5)",
  },
  {
    borderColor: "rgb(220,245,145)",
    backgroundColor: "rgba(220, 245, 145, 0.5)",
  },
  {
    borderColor: "rgb(17,16,16)",
    backgroundColor: "rgba(17, 16, 16, 0.5)",
  },
  {
    borderColor: "rgb(11,159,146)",
    backgroundColor: "rgba(11, 159, 146, 0.5)",
  },
  {
    borderColor: "rgb(11,159,146)",
    backgroundColor: "rgba(11, 159, 146, 0.5)",
  },
  {
    borderColor: "rgb(255,255,0)",
    backgroundColor: "rgba(255, 255, 146, 0.5)",
  },
];
const tablesName = {
  admin: ["Tabella SSI", "Tabella FDD", "Tabella SSI - admin", "Tabella FDD - admin", "Modi SSI - admin", "Modi FDD - admin"],
  default: ["Tabella SSI", "Tabella FDD"],
};


function ModalAnalysisDetail({ analysisResult }) {

  const { role } = useUserAttributes();
  const isAdmin = role.includes("admin");
  // console.log(isAdmin);

  const psdChartRefInstance = useRef(null);
  const firstPsdChartRefInstance = useRef(null);
  const psdChartRefFunctions = createChartRefFunctions(psdChartRefInstance);
  const firstPsdChartRefFunctions = createChartRefFunctions(firstPsdChartRefInstance);

  // 1. Diagramma XY - PSD (Power Spectral Density):
  const [PSDValueDataset, setPSDValueDataset] = useState([]);
  const [PSDValueLabels, setPSDValueLabels] = useState([]);
  const [psd_min_freq, setPsdMinFreq] = useState(0);
  const [psd_max_freq, setPsdMaxFreq] = useState(20);

  // 6. Primo valore singolare PSD
  const [firstSingularPSDValueData, setFirstSingularPSDValueData] = useState([]);
  const [firstSingularPSDValueLabels, setFirstSingularPSDValueLabels] = useState([]);

  // 9. Indici danno
  const [indiciDannoData, setIndiciDannoData] = useState([]);
  const [indiciDannoLabels, setIndiciDannoLabels] = useState([]);




  useEffect(() => {
    window.scrollTo(0, 0);
    // Set Graph data
    // 1.
    let min_element_involved_index = 0;
    let max_element_involved_index = 0;
    analysisResult.result.f_PSD.forEach((element) => {
      if (psd_min_freq > element) {
        min_element_involved_index += 1;
      }
      if (element < psd_max_freq) {
        max_element_involved_index += 1;
      }
    });
    setPSDValueLabels(
      analysisResult.result.f_PSD.slice(
        min_element_involved_index,
        max_element_involved_index
      )
    );
    let psd_dataset = [];
    analysisResult.result.PSD.forEach((psd, index) => {
      psd_dataset.push({
        label: "PSD " + index,
        data: psd.slice(min_element_involved_index, max_element_involved_index),
        borderColor: psd_color_map[index].borderColor,
        backgroundColor: psd_color_map[index].backgroundColor,
        hidden: index !== 0,
        pointRadius: 0,
        borderWidth: 1,
      });
    });
    setPSDValueDataset(psd_dataset);

    // // 6.
    setFirstSingularPSDValueLabels(
      analysisResult.result.f_PSD.slice(
        min_element_involved_index,
        max_element_involved_index
      )
    );
    setFirstSingularPSDValueData(analysisResult.result.cmif);

    // 9.
    setIndiciDannoData(analysisResult.result.indici_danno);
    let indici_danno_indexes = [];
    indici_danno_indexes = analysisResult.result.indici_danno.map(
      (_, index) => "Index " + index
    );
    setIndiciDannoLabels(indici_danno_indexes);
  }, [psd_min_freq, psd_max_freq, analysisResult]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ScrollProvider>
      <Grid
        item
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
          <Typography variant='h4' fontWeight={700} >Dettagli analisi modale</Typography>
        </Box>

        <Divider variant="middle" />

        {/* <ChartXRange setMinFreq={setPsdMinFreq} setMaxFreq={setPsdMaxFreq} /> */}
        <Grid item >
          <PSDChart
            PSDValueDataset={PSDValueDataset}
            PSDValueLabels={PSDValueLabels}
            zoomOptions={chartZoomOptions}
            chartRef={psdChartRefInstance}
            onResetZoom={psdChartRefFunctions.onResetZoom}
            onZoomPluse={psdChartRefFunctions.onZoomPluse}
            onZoomMinus={psdChartRefFunctions.onZoomMinus}
            onPanPluse={psdChartRefFunctions.onPanPluse}
            onPanMinus={psdChartRefFunctions.onPanMinus}
          />
        </Grid>
        <Divider variant='middle' />
        {isAdmin &&
          <>
              <Grid item >
                <FirstPSDChart
                  firstSingularPSDValueData={firstSingularPSDValueData}
                  firstSingularPSDValueLabels={firstSingularPSDValueLabels}
                  zoomOptions={chartZoomOptions}
                  chartRef={firstPsdChartRefInstance}
                  onResetZoom={firstPsdChartRefFunctions.onResetZoom}
                  onZoomPluse={firstPsdChartRefFunctions.onZoomPluse}
                  onZoomMinus={firstPsdChartRefFunctions.onZoomMinus}
                  onPanPluse={firstPsdChartRefFunctions.onPanPluse}
                  onPanMinus={firstPsdChartRefFunctions.onPanMinus}
                />
              </Grid>

            <Divider variant='middle' />
          </>
        }
        <Grid item >
          <IndexDamageRadar
            indexDamageRadarData={indiciDannoData}
            indexDamageRadarLabels={indiciDannoLabels}
          />
        </Grid>
        <Divider variant='middle' />

        <Element name="top" >
        <UserChips chipConfig={tablesName}/>
        </Element>

        <ScrollTopButton />

        <Element name="Tabella SSI">
          <Grid item >
            <TabellaSSI analysisResult={analysisResult} />
          </Grid>
        </Element>
        <Divider variant='middle' />

        <Element name="Tabella FDD">
          <Grid item >
            <TabellaFDD analysisResult={analysisResult} />
          </Grid>
        </Element>

        <Divider variant='middle' />

        {isAdmin &&
          <>
            <Element name="Tabella SSI - admin" >
              <Grid item >
                <TabellaSSIadmin analysisResult={analysisResult} />
              </Grid>
            </Element>

            <Divider variant='middle' />
            
            <Element name="Tabella FDD - admin">
              <Grid item >
                <TabellaFDDadmin analysisResult={analysisResult} />
              </Grid >
            </Element>
            
            <Divider variant='middle' />
            
            <Element name="Modi SSI - admin">
              <Grid item >
                <ModiSSIadmin analysisResult={analysisResult} />
              </Grid>
            </Element>
            
            <Divider variant='middle' />

            <Element name="Modi FDD - admin">
              <Grid item >
                <ModiFDDadmin analysisResult={analysisResult} />
              </Grid >
            </Element>
          </>
        }
      </Grid >

    </ScrollProvider>
  );
}

export default ModalAnalysisDetail;
