import { Chip, Grid, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
    height: 150,
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#F1F1F1",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};


function StyledDropzone(props) {
    const text = `Only ${Object.entries(props.acceptedFileFormats).map(([, value]) => value.join(", ")).join(", ")} will be accepted`;
    // const text = `Only ${props.acceptedFileFormats.join(", ")} will be accepted`;
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles) {
            if (props.setFile) {
                props.setFile(acceptedFiles);
            } else {
                console.log("no registration method provided");
            }
        }
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: props.acceptedFileFormats,
        maxFiles: 1,
        disabled: props.disabled,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(props.disabled ? { cursor: "default" } : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const handleDelete = () => {
        if (props.setFile) {
            props.setFile([]);
        }
    };

    return (
        <Grid item container justifyContent="center">
            <Grid {...getRootProps({ style })} item container justifyContent="center" direction="column">
                <input {...getInputProps()} />
                <Grid item  sx={{paddingX: 2}}>
                    <Typography textAlign={'center'} >
                    Drag and drop a file here or click to select one.<br/>{text}
                    </Typography>
                    </Grid>
            </Grid>
            {props.file && props.file.length ?
                <Grid item container justifyContent="center" xs={12} sx={{ marginTop: 2 }}>
                    <Chip
                    variant="outlined"
                    color="default" 
                    label={props.file[0].name} 
                    onDelete={handleDelete} />
                </Grid> : undefined
            }
        </Grid>
    );
}

export default StyledDropzone;
