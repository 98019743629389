import React from "react";
import { Grid, Typography } from "@mui/material";
import { Line, Radar } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
import { ChartContainerBig } from "../Theme/styledComponents";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

// 1.
function IndexDamageRadar({
  indexDamageRadarData,
  indexDamageRadarLabels,
}) {
  const theme = useTheme();

  const indici_danno_chart = {
    labels: indexDamageRadarLabels,
    datasets: [
      {
        label: "Indici Danno",
        data: indexDamageRadarData,
        backgroundColor: "rgba(94,164,176,0.2)",
        borderColor: "rgb(78,166,238)",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        labels: {
          color: theme.palette.white.main,
        }
      }
    },
    scales: {
      r: {
        ///// definire colore e background ticks
        ticks: {
          color: theme.palette.blue.main,
          backdropColor: "rgba(0,0,0,0)"
        },
        //// definire colore griglia
        grid: {
          color: theme.palette.grey.main,
        },
        //// definire colore labels esterni
        pointLabels: {
          color: theme.palette.white.main,
        }
      }
    },
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.gradient.main,
          borderRadius: "8px",
          width: '50%',
          height: '50%',
          margin: "0 auto"
        }}
      >
        <Box sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          padding: 3  
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} >PSD (Power Spectral Density)</Typography>
        </Box>

        <Radar options={options} data={indici_danno_chart} />
      </Box>
    </>
  );
}

export default IndexDamageRadar;
