import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function Footer() {
    const theme = useTheme();
    return (
        <Box
            component="footer"
            sx={{
                position: "relative",
                zIndex: 3,
                // (theme) => theme.zIndex.drawer + 1,
                backgroundColor: theme.palette.primary.main,
                p: 2,
                textAlign: "center",
                boxShadow: "0px -5px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
        >
            <Typography variant="body2" color={theme.palette.primary.contrastText} >
            Note legali | Credits | Privacy policy | Cookie policy | Crediti fotografici | P. IVA IT 03735391207
            </Typography>
        </Box>
    );
}