import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledDataGrid } from "../Theme/styledComponents";
import MyDataGrid from "../UI/DataGrid";


function TabellaSSIadmin({ analysisResult }) {
  const [ssiAdminRows, setSssiAdminRows] = useState([]);
  const [ssiAdminColumns, setSsiAdminColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState("80vw");

  useEffect(() => {
    let ssi_admin_columns = [
      {
        field: "measurement_name",
        headerName: "Name",
        flex: 1,
      },
    ];
    let ssi_admin_first_row = {
      id: "ssi_admin_first_row",
      measurement_name: "Freq.",
    };
    let ssi_admin_second_row = {
      id: "ssi_admin_second_row",
      measurement_name: "Freq sel.",
    };
    let ssi_admin_third_row = {
      id: "ssi_admin_third_row",
      measurement_name: "Smorz.",
    };
    analysisResult.result.freq_SSI.forEach((ssi, index) => {
      ssi_admin_columns.push({
        field: "ssi_admin_" + index,
        headerName: "Modo " + (index + 1),
        flex: 1,
      });
      ssi_admin_first_row["ssi_admin_" + index] = ssi;
    });
    setSsiAdminColumns(ssi_admin_columns);
    // Metto freq_SSI_sel
    analysisResult.result.freq_SSI_sel.forEach((ssi, index) => {
      ssi_admin_second_row["ssi_admin_" + index] = ssi;
    });
    // Metto smorz_SSI
    analysisResult.result.smorz_SSI.forEach((ssi, index) => {
      ssi_admin_third_row["ssi_admin_" + index] = ssi;
    });
    let ssi_admin_rows = [];
    ssi_admin_rows.push(ssi_admin_first_row);
    ssi_admin_rows.push(ssi_admin_second_row);
    ssi_admin_rows.push(ssi_admin_third_row);
    // Metto modi_SSI_sel
    analysisResult.result.modi_SSI_sel.forEach((modi_ssi_sel, index) => {
      let row = {
        id: "ssi-sel-row-" + index,
        measurement_name: "Sens. " + (index + 1),
      };
      modi_ssi_sel.forEach((modo, i) => {
        row["ssi_admin_" + i] = modo;
      });
      ssi_admin_rows.push(row);
    });
    setSssiAdminRows(ssi_admin_rows);
  }, [analysisResult]);

  useEffect(() => {
    let totalWidth = 0;
    ssiAdminColumns.forEach((column) => {
      totalWidth += column.width;
    });
    setTableWidth(totalWidth);
  }, [ssiAdminColumns]);

  return (
    <>
      <Box >
        <Typography variant='h5' color="text.secondary" fontWeight={600} >
        4. Tabella con i modi identificati dal metodo SSI (Stocastic Subspace
        Identification) - SOLO PER SUPER ADMIN
        </Typography>
      </Box>
        <Box
          id={"SSI-table-admin"}
          sx={{
            height: 700

            // width: tableWidth || "80vw",
            // maxWidth: "80vw",
          }}
        >
          <MyDataGrid
            rows={ssiAdminRows}
            columns={ssiAdminColumns}
            getCellClassName={(params) => {
              if (
                [
                  "ssi_admin_first_row",
                  "ssi_admin_second_row",
                  "ssi_admin_third_row",
                ].includes(params.id)
              ) {
                return "first_row_table";
              }
              return "";
            }}
          />
        </Box>
    </>
  );
}

export default TabellaSSIadmin;
