import {Environment, OrbitControls, useGLTF} from "@react-three/drei";
import React, {Suspense, useRef,useState} from "react";
import {Canvas} from "@react-three/fiber";
import { Button } from "@mui/material";

useGLTF.preload('/3d_model/bridge_1.glb')
export default function Bridge1Model(props) {
    const { nodes, materials } = useGLTF('/3d_model/bridge_1.glb')
    const ref = useRef()
    const [orbitControls, setOrbitControls] = useState(null);

    function handleResetOrbit() {
        if (orbitControls) {
          orbitControls.target.set(0, 0, 0);
          orbitControls.object.position.set(40, 0, 0);
          orbitControls.update();
        }
      }
    return (
    <>
        <Button onClick={handleResetOrbit} variant="contained" color="primary" size="small">Reset Orbit</Button>
        <Canvas shadows camera={{ position: [10, 30, 90], fov: 25 }}>
            <ambientLight intensity={0.7} />
            {/*<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />*/}
            <Suspense fallback={null}>
                <group
                    {...props}
                    dispose={null}
                    ref={ref}
                    onClick={(e) => {
                        e.stopPropagation()
                        props.setElementSelected(e.object.material.name)
                    }}>
                    <group position={[10.84, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube001_1.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube001_2.geometry} material={materials['Material.010']} />
                    </group>
                    <group position={[-11.01, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube000_1.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube000_2.geometry} material={materials['Material.010']} />
                    </group>
                    <group position={[7.16, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube017.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube017_1.geometry} material={materials['Material.010']} />
                    </group>
                    <group position={[3.53, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube016.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube016_1.geometry} material={materials['Material.010']} />
                    </group>
                    <group position={[-0.13, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube015.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube015_1.geometry} material={materials['Material.010']} />
                    </group>
                    <mesh geometry={nodes.Plane001.geometry} material={materials['Material.008']} position={[0.79, -1.11, 0.34]} scale={0.53} />
                    <group position={[8.55, 0.87, 0.46]} rotation={[Math.PI, 0, Math.PI]} scale={[0.19, 0.28, 0.19]}>
                        <mesh geometry={nodes.Cube010.geometry} material={materials['Material.001']} />
                        <mesh geometry={nodes.Cube010_1.geometry} material={materials.light} />
                        <mesh geometry={nodes.Cube010_2.geometry} material={materials['Material.003']} />
                        <mesh geometry={nodes.Cube010_3.geometry} material={materials['Material.004']} />
                        <mesh geometry={nodes.Cube010_4.geometry} material={materials['Material.005']} />
                        <mesh geometry={nodes.Cube010_5.geometry} material={materials['Material.006']} />
                        <mesh geometry={nodes.Cube010_6.geometry} material={materials['Material.007']} />
                        <mesh geometry={nodes.Cube010_7.geometry} material={materials['light.001']} />
                        <mesh geometry={nodes.Cube010_8.geometry} material={materials['Material.002']} />
                    </group>
                    <group position={[-3.79, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube009_1.geometry} material={materials['Material.011']} />
                        <mesh geometry={nodes.Cube009_2.geometry} material={materials['Material.012']} />
                    </group>
                    <group position={[-1.96, 0.87, 0.46]} scale={[0.19, 0.28, 0.19]}>
                        <mesh geometry={nodes.Cube008_1.geometry} material={materials['Material.001']} />
                        <mesh geometry={nodes.Cube008_2.geometry} material={materials.light} />
                        <mesh geometry={nodes.Cube008_3.geometry} material={materials['Material.003']} />
                        <mesh geometry={nodes.Cube008_4.geometry} material={materials['Material.004']} />
                        <mesh geometry={nodes.Cube008_5.geometry} material={materials['Material.005']} />
                        <mesh geometry={nodes.Cube008_6.geometry} material={materials['Material.006']} />
                        <mesh geometry={nodes.Cube008_7.geometry} material={materials['Material.007']} />
                        <mesh geometry={nodes.Cube008_8.geometry} material={materials['light.001']} />
                        <mesh geometry={nodes.Cube008_9.geometry} material={materials['Material.002']} />
                    </group>
                    <mesh geometry={nodes.Plane.geometry} material={materials.Material} position={[-0.06, 0.83, 0.4]} scale={0.5} />
                    <group position={[-7.3, 1.85, 0.46]} scale={[1.85, 0.09, 1.09]}>
                        <mesh geometry={nodes.Cube005_1.geometry} material={materials['Material.009']} />
                        <mesh geometry={nodes.Cube005_2.geometry} material={materials['Material.010']} />
                    </group>
                    <group position={[-9.05, 0.87, 0.46]} scale={[0.19, 0.28, 0.19]}>
                        <mesh geometry={nodes.Cube004_1.geometry} material={materials['Material.001']} />
                        <mesh geometry={nodes.Cube004_2.geometry} material={materials.light} />
                        <mesh geometry={nodes.Cube004_3.geometry} material={materials['Material.003']} />
                        <mesh geometry={nodes.Cube004_4.geometry} material={materials['Material.004']} />
                        <mesh geometry={nodes.Cube004_5.geometry} material={materials['Material.005']} />
                        <mesh geometry={nodes.Cube004_6.geometry} material={materials['Material.006']} />
                        <mesh geometry={nodes.Cube004_7.geometry} material={materials['Material.007']} />
                        <mesh geometry={nodes.Cube004_8.geometry} material={materials['light.001']} />
                        <mesh geometry={nodes.Cube004_9.geometry} material={materials['Material.002']} />
                    </group>
                </group>
                <Environment preset="city" />
                {/*<ContactShadows position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />*/}
            </Suspense>
            {/*<OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />*/}
            <OrbitControls maxPolarAngle={Math.PI / 2} minDistance={5} maxDistance={60} enableZoom={true} enablePan={true} ref={(controls) => setOrbitControls(controls)}/>
        </Canvas>
    </>
    )
}
