import { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const useUserAttributes = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [account, setAccount] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");

  useEffect(() => {
    const currentAccount = user?.getSignInUserSession()?.getIdToken();
    setAccount(currentAccount?.payload["email"]);
    setRole(currentAccount?.payload["cognito:groups"]);
    setCompany(currentAccount?.payload["gender"]);
  }, [user]);

  return { account, role, company };
}
