import { ThemeProvider } from "@emotion/react";
import "./App.css";
import {theme} from "./components/Theme/theme";
import { RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import router from "./components/Routes/Router";



function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <RouterProvider router={router} >
        </RouterProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
