import React from 'react';
import { useUserAttributes } from '../components/Auth/Hooks/UserDetails';
import useDetailedPermission from '../components/Auth/Hooks/SpecificAuthorizationHook';
import { Typography, Divider, Grid, Box } from '@mui/material';
// import { useAuthenticator } from '@aws-amplify/ui-react';

function AnalysisCatalogCreation() {

    // const authorized = useDetailedPermission(["admin", "editor"], true); // tutti per forza    
    const authorized = useDetailedPermission(["admin", "editor"], false); // almeno uno   
    const { account, role, company } = useUserAttributes();

    return (
        <>
            <Grid item container direction='column'  >
                <Box
                    sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                    <Typography variant='h4' fontWeight={700} > Creazione Catalogo Analisi</Typography>
                </Box>

                <Divider variant='middle' sx={{ width: '100%', mt: 2, mb: 2 }} />


                {authorized ? (
                    <Typography variant="body2">Benvenuto {account}, <br /> dell'azienda: {company}, <br /> hai il ruolo di {role[0]}, puoi proseguire alla creazione delle analisi. </Typography>
                ) : (
                    <Typography variant="body2">Benvenuto {account}, <br /> dell'azienda: {company}, <br /> hai il ruolo di {role[0]}, non puoi proseguire. </Typography>
                )}
            </Grid>
        </>
    );
}

export default AnalysisCatalogCreation;