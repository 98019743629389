import React from 'react'
import { Box, Button, Divider, Grid, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import { ReactComponent as deleteIcon} from '../icon/deleteIcon.svg'

function AddProject() {

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      region: '',
      infrastructuresNumber: '',
    }
  });

  const handleReset = () => {
    reset();
  }
  const onSubmit = (data) => {

    console.log(data)
  }

  return (
    <>
      <Grid item container direction='column' alignItems={'center'}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

          <Typography variant="h4" fontWeight={700}>Aggiungi un progetto</Typography>
        </Box>

        <Divider variant='middle' sx={{ width: '100%', mt: 2 }} />

        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} width={400} sx={{ mt: 2 }}>
              <TextField
                {...register('name', {
                  required: 'Obbligatorio',
                  minLength: { value: 5, message: 'Almeno 5 caratteri' },
                  maxLength: { value: 10, message: 'Massimo 10 caratteri' }
                })}
                autoComplete='off'
                label='Nome'
                variant='outlined'
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                {...register('region', {
                  required: 'Obbligatorio',
                  minLength: { value: 5, message: 'Almeno 5 caratteri' },
                  maxLength: { value: 10, message: 'Massimo 10 caratteri' }
                })}
                autoComplete='off'
                label='Regione'
                variant='outlined'
                error={!!errors.region}
                helperText={errors.region?.message}
              />
              <TextField
                {...register('infrastructuresNumber', {
                  required: 'Obbligatorio',
                  pattern: { value: /^[1-9]*$/, message: 'Solamente numeri da 1' }
                })}
                autoComplete='off'
                label='N. infrastrutture'
                variant='outlined'
                error={!!errors.infrastructuresNumber}
                helperText={errors.infrastructuresNumber?.message}
              />
              <Stack direction="row" alignItems="flex-start" spacing={2} justifyContent='space-between'>
                <Stack direction="row" alignItems="flex-start" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                  startIcon={<SvgIcon component={deleteIcon} viewBox="0 0 17 17" />}
                    variant="contained"
                    sx={{
                      backgroundColor: "secondary.main",
                      '&:hover': {
                        backgroundColor: "secondary.hover"
                      }
                    }}  >
                    Cancel
                  </Button>
                </Stack>
                <Button
                  onClick={handleReset}
                  variant="text"
                  sx={{
                    textDecoration: "underline",
                    backgroundColor: "#00000000",
                    color: "brightAzure.main",
                    '&:hover': {
                      color: "brightAzure.hover",
                      backgroundColor: "#00000000",
                    }
                  }}  >
                  Reset form
                </Button>
              </Stack>
            </Stack>

          </form>
        </Box>

      </Grid>
    </>
  )
}

export default AddProject