import React, { useState, useEffect } from "react";
import analysisResults from "../data/analysis_result_example.json";
import { GridButton } from "../components/Theme/styledComponents";
import { Box, Divider, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { StyledLink } from "../components/Theme/styledComponents";
import moment from "moment";
import ModalCrossCharts from "../components/ModalCrossCharts";
import ThresholdsCrossCharts from "../components/ThresholdsCrossCharts";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import CalendarRange from "../components/UI/Calendar";
import MyDataGrid from "../components/UI/DataGrid";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);


// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return format(date, "dd/MM/yyyy - HH:mm:ss");
// };
//utc

function AnalysisResults() {
  const { id, infrastructureId, resultsId } = useParams();
  const [resoultFound, setResoultFound] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredModalResults, setFilteredModalResults] = useState([]);
  const [filteredThresholdResults, setFilteredThresholdResults] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    const list = analysisResults.filter(
      (result) => result.analisi_id === resultsId
    );
    setResoultFound(list);
  }, [resultsId]);
  console.log(resoultFound);

  useEffect(() => {
    const filteredList = resoultFound.filter((result) =>
      startDate && endDate
        ? dayjs(result.execution_date).isBetween(dayjs(startDate).startOf('day'), dayjs(endDate).endOf('day'), null, "[]")
        : true
    );

    setFilteredModalResults(filteredList);
    setFilteredThresholdResults(filteredList);
  }, [resoultFound, startDate, endDate]);

  console.log(filteredModalResults);

  const renderResult = (params) => {
    return (
          <GridButton 
            component={StyledLink}
            to={`/project/${id}/projectInfrastructure/${infrastructureId}/analysisType/${params.row.id}/analysisDetail`}
            variant="outlined" >
              Dettagli
          </GridButton>
    );
  };
  const formatDate = (dateString) => {
    return moment.utc(dateString).format("DD/MM/YYYY - HH:mm:ss");
  };
  const columns = [
    {
      field: "execution_date",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => formatDate(params.row.execution_date),
    },
    {
      field: "action",
      headerName: "Azioni",
      flex: 0.5,
      align: "right",
      headerAlign: "right",
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderResult,
    },
  ];

  return (
    <>
      <Grid item sx={{ width: "100%" }} >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }} >
          <Typography variant='h4' fontWeight={700} >Risultati Analisi</Typography>
          <Typography variant='subtitle2' fontWeight={400} >Cross Charts and Details</Typography>
        </Box>

        <Divider variant="middle" sx={{ py: 2 }} />

        <Grid item display="flex" sx={{ mb: 10 }} >
          <CalendarRange
            startDate={startDate}
            endDate={endDate}
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
          />
        </Grid>

        {resoultFound &&
          resoultFound.some((result) => result.id === "result_analysis_modale_1") && (
            <ModalCrossCharts filteredResoultFound={filteredModalResults} />
          )}
        {resoultFound &&
          resoultFound.some((result) => result.id === "result_analysis_soglie_1") && (
            <ThresholdsCrossCharts filteredResoultFound={filteredThresholdResults} />
          )}

        <Grid item
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Box
            width={"50%"}
            height={400}

          >
            <MyDataGrid
              columns={columns}
              rows={resoultFound.filter((result) =>
                startDate && endDate
                  ? dayjs(result.execution_date).isBetween(startDate, endDate, null, "[]")
                  : true
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default AnalysisResults;
