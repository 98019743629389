import { Button } from '@mui/material';
import React from 'react';
import { StyledLink } from '../Theme/styledComponents';

const LinkButton = ({ to, children, fullWidth, ...props }) => {
  return (
    <StyledLink to={to} >
      <Button fullWidth={fullWidth} {...props}>
        {children}
      </Button>
    </StyledLink>
  );
}

export default LinkButton;
