import React from "react";
import { Grid, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
import { ChartContainerBig } from "../Theme/styledComponents";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import BoxCharts from "../UI/BoxCharts";

// 1.
function PSDChart({
  PSDValueDataset,
  PSDValueLabels,
  zoomOptions,
  chartRef,
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) {
  const theme = useTheme();

  const psd_config = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: theme.palette.white.main,
        }
      },
      title: {
        display: true,
        text: "Power Spectral Density",
        color: theme.palette.white.main,
      },
      zoom: zoomOptions,
    },
    scales:{
      x:{
        ticks:{
          color: theme.palette.white.main,
        }
      },
      y:{
        ticks:{
          color: theme.palette.white.main,
        }
      }
    }
  };

  const psd_chart_data = {
    labels: PSDValueLabels,
    datasets: PSDValueDataset,
  };

  return (
    <>
      <BoxCharts minHeight="380px" maxHeight="700px" >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 3
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} >PSD (Power Spectral Density)</Typography>
          <ZoomControls
            onResetZoom={onResetZoom}
            onZoomPluse={onZoomPluse}
            onZoomMinus={onZoomMinus}
            onPanPluse={onPanPluse}
            onPanMinus={onPanMinus}
          />
        </Box>
        <Line
          height={"100%"}
          options={psd_config}
          data={psd_chart_data}
          ref={chartRef}
        />
      </BoxCharts>
    </>
  );
}

export default PSDChart;
