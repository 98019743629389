import React, { useState } from 'react';
import { AppBar, Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { ReactComponent as Logo3S } from "../../img/LOGO3SBright.svg"
import LogOutDetail from '../Auth/LogOutDetail';
import NavigationItems from './NavigationItems';
import DrawerNavigationMenu from './DrawerNavigationMenu';
import CustomBreadcrumbs from '../UI/BreadCrumbs';
import { Menu } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

function AppBarAndDrawer() {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [closeTimeout, setCloseTimeout] = useState(null);

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleMouseLeaveMenu = () => {
        // Imposta un timeout per chiudere il drawer dopo un breve ritardo
        setCloseTimeout(setTimeout(handleDrawerClose, 100)); // Ritardo di 100ms
    };

    const handleMouseEnterDrawer = () => {
        // Se il mouse entra nel drawer prima che il timeout sia scaduto, annulla il timeout
        if (closeTimeout) {
            clearTimeout(closeTimeout);
        }
        handleDrawerOpen();
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <AppBar
                    component="nav"
                    position='fixed'
                    color="primary"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingX: { sm: "48px", md: "100px", lg: "100px" }
                    }}
                >
                    <Box sx={{ display: { sm: "flex", md: "none", lg: "none" }, alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Box>
                    <Box display={{ md: "flex", lg: "flex", xl: "flex" }}>
                        <Box sx={{ display: "flex", alignItems: "center", pr: { sm: "0px", md: "0px", lg: "96px" } }} >
                            <Logo3S style={{ width: 101, height: 36.3 }} />
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" }, pr: { sm: 0, md: 0, lg: 0, xl: "500px" } }}>
                            <NavigationItems
                                handleDrawerOpen={handleDrawerOpen}
                                handleDrawerClose={handleMouseLeaveMenu}
                            />
                        </Box>
                    </Box>
                    <Box onMouseEnter={handleDrawerClose} >
                        <LogOutDetail />
                    </Box>
                </AppBar>
                <CustomBreadcrumbs />
                <Drawer
                    onMouseEnter={handleMouseEnterDrawer}
                    onMouseLeave={handleDrawerClose}
                    variant="persistent"
                    open={isDrawerOpen}
                    onClose={handleDrawerToggle}
                    anchor={isSmallScreen ? 'left' : 'top'} 
                    // transitionDuration={{ enter: 1500, exit: 1000 }}
                    sx={{
                        flexShrink: 0,
                        // height: "auto",
                        width: { sm: "50%", md: "100%" },
                        [`& .MuiDrawer-paper`]: {
                            // width: "100%",
                            width: { sm: "50%", md: "100%" },
                            boxSizing: "border-box",
                            borderBottom: `2px solid ${theme.palette.primary.contrastText} `,
                            backgroundColor: "#171D27",
                            boxShadow: "0px 4px 15px rgba(23, 29, 39, 0.16)"
                        },
                    }}
                >
                    <DrawerNavigationMenu  handleDrawerClose={handleDrawerClose} />
                </Drawer>
            </Box>
        </>
    );
}

export default AppBarAndDrawer;