import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import ZoomControls from "../UI/ChartZoomControls";
import { useTheme } from "@emotion/react";
import BoxCharts from "../UI/BoxCharts";

//11.
function ThresholdsGlobalDamage({
  semaforoData,
  semaforoLabels,
  zoomOptions,
  chartRef,
  onResetZoom,
  onZoomPluse,
  onZoomMinus,
  onPanPluse,
  onPanMinus,
}) {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const theme = useTheme();

  const Threshoulds_Global_Damage_config = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: theme.palette.white.main,
        }
      },
      title: {
        display: true,
        text: "Global Damage Index",
        color: theme.palette.white.main,
      },
      zoom: zoomOptions,
    },
    scales:{
      x:{
        ticks:{
          color: theme.palette.white.main,
        }
      },
      y:{
        ticks:{
          color: theme.palette.white.main,
        }
      }
    }
  };
  // const Threshoulds_Global_Damage_chart_data = {
  //   labels: semaforoLabels,
  //   datasets: [
  //     {
  //       label: "Indici di Danno Globale",
  //       data: semaforoData,
  //       borderColor: "rgba(75, 192, 192, 1)",
  //       backgroundColor: "rgba(75, 192, 192, 0.2)",
  //     },
  //   ],
  // };

  useEffect(() => {
    setChartData({
      labels: semaforoLabels,
      datasets: [
        {
          label: semaforoData.length ? "Indici di Danno Globale" : "",
          data: semaforoData,
          borderColor: semaforoData.length ? "rgba(75, 192, 192, 1)" : "rgba(0,0,0,0)",
          backgroundColor: semaforoData.length ? "rgba(75, 192, 192, 0.2)" : "rgba(0,0,0,0)",
        },
      ],
    });
  }, [semaforoData, semaforoLabels]);

  return (
    <>
      <BoxCharts minHeight="380px" maxHeight="700px" >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: 3
        }} >
          <Typography variant='h5' color="text.secondary" fontWeight={600} >Indici di danno globale soglie</Typography>
          <ZoomControls
            onResetZoom={onResetZoom}
            onZoomPluse={onZoomPluse}
            onZoomMinus={onZoomMinus}
            onPanPluse={onPanPluse}
            onPanMinus={onPanMinus}
          />
        </Box>

        <Line
          height={'100%'}
          options={Threshoulds_Global_Damage_config}
          data={chartData}
          ref={chartRef}
        />
      </BoxCharts>
    </>
  );
}

export default ThresholdsGlobalDamage;
