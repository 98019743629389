import { Autocomplete, TextField } from "@mui/material";

function SearchBar({ options, handleSearchChange, label, width, icon }) {
    return (
        <Autocomplete
            freeSolo
            open={false}
            options={options}
            onInputChange={handleSearchChange}
            sx={{ width: width }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {icon}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export default SearchBar;
