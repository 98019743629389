import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Divider, Grid, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import infrastructures from '../data/infrastructure.json'
import { DevTool } from '@hookform/devtools'
import { ReactComponent as deleteIcon } from '../icon/deleteIcon.svg';
import StyledDropzone from '../components/UI/DropZone';

function ModifyInfrastructure() {

  const { infrastructureId } = useParams();
  const [infrastructureFound, setInfrastructureFound] = useState();
  const [originalFile, setOriginalFile] = useState();
  const [file, setFile] = useState([]);

  const { register, handleSubmit, setValue, reset, formState: { errors }, control } = useForm({
    defaultValues: {
      total_sensors: "",
      project_id: "",
      id: "",
      model_TwoD: [],
    }
  });
  const handleReset = (file) => {
    reset();
    setFile();
  }

  const handleFileChange = (newFile) => {
    setFile(newFile);

    if(newFile.length === 0) {
      setValue('model_TwoD', originalFile);
    } else {
      setValue('model_TwoD', newFile);
    }
  };

  const onSubmit = (data) => {
    setInfrastructureFound(prevState => {
      const newState = {
        ...prevState,
        ...data,
      };
      console.log("Updated state: ", newState);
      return newState;
    });
    // console.log(data)
  };



  useEffect(() => {
    const infrastructure = infrastructures.filter(infrastructure => infrastructure["id"] === infrastructureId)[0]
    setInfrastructureFound(infrastructure)

    if (infrastructure) {
      for (const [key, value] of Object.entries(infrastructure)) {
        setValue(key, value);
      }
    }
    setOriginalFile(infrastructure.model_TwoD);

  }, [infrastructureId, setValue])// eslint-disable-line react-hooks/exhaustive-deps

  console.log("sonofound", infrastructureFound);

  return (
    <>

      <Grid item container direction='column' sx={{width: '100%'}} >
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
          <Typography variant='h4' fontWeight={700} > Modifica infrastruttura</Typography>
        </Box>

        <Divider variant='middle' sx={{ mt: 2, mb: 2 }} />

          <Grid display={'flex'} justifyContent={'center'}>
            <Box sx={{width:'70%'}}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} width={'100%'} sx={{ mt: 2 }} >
                  <TextField
                    {...register("project_id", { required: 'Obbligatorio' })}
                    autoComplete='off'
                    variant="outlined"
                    label="ID Progetto"
                    error={!!errors.project_id}
                    helperText={errors.project_id?.message}
                  />
                  <TextField
                    {...register("id", { required: 'Obbligatorio' })}
                    autoComplete='off'
                    variant="outlined"
                    label="ID Infrastruttura"
                    error={!!errors.id}
                    helperText={errors.id?.message}
                  />
                  <TextField
                    {...register("total_sensors", { required: 'Obbligatorio' })}
                    autoComplete='off'
                    variant="outlined"
                    label="Numero di sensori"
                    error={!!errors.total_sensors}
                    helperText={errors.total_sensors?.message}
                  />
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Box sx={{ width: '100%'}}>
                      <StyledDropzone
                        acceptedFileFormats={{
                          'image/*': ['.jpeg', '.png']
                        }}
                        file={file}
                        setFile={handleFileChange}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" alignItems="flex-start" spacing={2} justifyContent='space-between'>
                    <Stack direction="row" alignItems="flex-start" spacing={2}>
                      <Button
                        variant="contained"
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        startIcon={<SvgIcon component={deleteIcon} viewBox="0 0 17 17" />}
                        variant="contained"
                        sx={{
                          backgroundColor: "secondary.main",
                          '&:hover': {
                            backgroundColor: "secondary.hover"
                          }
                        }}
                        >
                        Cancel
                      </Button>
                    </Stack>
                    <Button
                      onClick={handleReset}
                      variant="text"
                      sx={{
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                        color: "brightAzure.main",
                        '&:hover': {
                          color: "brightAzure.hover",
                          backgroundColor: "transparent",
                        }
                      }}  >
                      Reset form
                    </Button>
                  </Stack>
                </Stack>
              </form >
            </Box>
          </Grid>
        </Grid>
        <DevTool control={control} />
    </>
  );
}

export default ModifyInfrastructure