import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LOGO3SDark from "../src/img/LOGO3SDark.svg";

import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-plugin-zoom";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  PointElement,
  Filler,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage("it");

I18n.putVocabularies({
  it: {
    "We Texted You": "Ti abbiamo inviato una email",
  },
});
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <div style={{ marginTop: "20vh" }}>
        <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src={LOGO3SDark}
        />
        </View>
      </div>
    );
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator hideSignUp={true} components={components}>
      <App />
    </Authenticator>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
