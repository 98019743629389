import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useAuthorization } from "../Auth/Hooks/DynamicAuthorizationHook";
import { navigationMenuItem } from "./MenuConfig";


const NavigationItem = ({ itemKey, itemText, linkTo, groups, handleDrawerClose, handleDrawerOpen }) => {

  const groupPermission = useAuthorization(groups);
  const menuItemProps = {};

// Controllo se itemKey è Menu
if (itemKey === "Menu") {
  // se si aggiungo onMouseEnter/leave solo a questo item
  menuItemProps.onMouseEnter = handleDrawerOpen;
  menuItemProps.onMouseLeave = handleDrawerClose;
}


  return (
    <>
      {groupPermission ? (
          <ListItem   disablePadding >
            <ListItemButton {...menuItemProps}
              key={itemKey}
              component="a"
              href={linkTo}            
              sx={{
                width: 'auto',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  outline: 'none',
                },
              }}>
              <ListItemText sx={{textAlign:"center"}} primary={itemText}  primaryTypographyProps={{fontSize:"10px",color:"text.primary"}} />
            </ListItemButton>
          </ListItem>
      ) : (
        <Tooltip title={groupPermission ? null : "Non autorizzato"}>
          <ListItem sx={{ width: 180 }} disablePadding>
            <ListItemButton disabled={!groupPermission}>
              <ListItemText sx={{textAlign:"center"}} primary={itemText} primaryTypographyProps={{fontSize:"10px",color:"text.primary"}}/>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </>
  );
};
function NavigationItems({ handleDrawerClose, handleDrawerOpen }) {

    return (
      <>
        {navigationMenuItem.map((menuItem) => (
        <Box key={menuItem.itemKey} sx={{ display: "flex", mr:4 }}>
          <List
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop:0,
              paddingBottom:0,
            }}
          >
            <NavigationItem
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              itemKey={menuItem.itemKey}
              itemText={menuItem.itemText.toUpperCase()}
              linkTo={menuItem.linkTo}
              groups={menuItem.groups}
            />
          </List>
        </Box>
      ))}
      </>
    );
}

export default NavigationItems;

// //////// for side menu
//  <Box sx={{ display: 'flex', justifyContent: 'center',overflow: "auto" }}>
//           <List onClick={handleDrawerClose}>
//             <SidebarItem
//               itemKey={"Pagina Iniziale"}
//               itemText={"Pagina Iniziale"}
//               tooltipTitle={"Pagina Iniziale"}
//               linkTo={"/"}
//               groups={[]}
//             />
//             <Divider />
//             <SidebarItem
//               itemKey={"Lista Progetti"}
//               itemText={"Lista Progetti"}
//               tooltipTitle={"Lista Progetti"}
//               linkTo={"/project"}
//               groups={["admin","adminRead"]}
//             />
//             <Divider />
//             <SidebarItem
//               itemKey={"Aggiungi Progetto"}
//               itemText={"Aggiungi Progetto"}
//               tooltipTitle={"Aggiungi Progetto"}
//               linkTo={"/addProject"}
//               groups={["adminRead"]}
//             />
//             <Divider />
//           </List>
//         </Box>


// // const SidebarItem = ({
// //   // ItemIcon,
// //   itemKey,
// //   itemText,
// //   linkTo,
// //   // groups,
// //   tooltipTitle,
// // }) => {
// //   return (
// //     <>
// //       <NavLink
// //         to={linkTo}
// //         key={itemKey}
// //
// //       >
// //         <ListItem disablePadding>
// //           <ListItemButton>
// //             {/* <Tooltip title={tooltipTitle}>
// //                     <ListItemIcon>{<ItemIcon />}</ListItemIcon>
// //                   </Tooltip> */}
// //             <ListItemText  primary={itemText} />
// //           </ListItemButton>
// //         </ListItem>
// //       </NavLink>
// //     </>
// //   );
// // };


// /////// null unauthorized button
//       //       <>
//       //    {autorizzato ? (<StyledNavLink
//       //         to={linkTo}
//       //         key={itemKey}
//       //         style={{ textDecoration: "none", color: "primary" }}
//       //       >
//       //         <ListItem sx={{display: "flex"}} disablePadding>
//       //           <ListItemButton>
//       //             {/* <Tooltip title={tooltipTitle}>
//       //                     <ListItemIcon>{<ItemIcon />}</ListItemIcon>
//       //                   </Tooltip> */}
//       //             <ListItemText  primary={itemText} />
//       //           </ListItemButton>
//       //         </ListItem>
//       //       </StyledNavLink>) : null }
//       // </>
