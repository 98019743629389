import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const [activeChip, setActiveChip] = useState(null);

  const resetActiveChip = () => {
    setActiveChip(null);
  };

  return (
    <ScrollContext.Provider 
        value={{ 
            activeChip, setActiveChip, resetActiveChip
        }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useMyScroll = () => {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error('useScroll deve essere usato con ScrollProvider');
  }
  return context;
};
