import {Environment, OrbitControls, useGLTF} from "@react-three/drei";
import React, {Suspense, useRef,useState} from "react";
import {Canvas} from "@react-three/fiber";
import { Button } from "@mui/material";
useGLTF.preload('/3d_model/windmill_1.glb')
export default function Windmill1Model(props) {
    const { nodes, materials } = useGLTF('/3d_model/windmill_1.glb')
    const ref = useRef()
    const [orbitControls, setOrbitControls] = useState(null);

    function handleResetOrbit() {
        if (orbitControls) {
          orbitControls.target.set(0, 0, 0);
          orbitControls.object.position.set(40, 0, 0);
          orbitControls.update();
        }
      }

    return (
        <>
        {/* <Button onClick={handleResetOrbit} variant="contained" color="primary" size="small">Reset Orbit</Button> */}
        <Canvas shadows camera={{ position: [40, 0, 0], fov: 25 }}>
            <ambientLight intensity={0.7} />
            {/*<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />*/}
            <Suspense fallback={null}>
                <group
                    {...props}
                    dispose={null}
                    ref={ref}
                    scale={[1.4,1.4,1.4]} //grandezza modello
                    position={[0,-5,0]} //posizione modello 
                    onClick={(e) => {
                        e.stopPropagation()
                        props.setElementSelected(e.object.material.name)
                    }}>
                    <group rotation={[Math.PI / 2, 0, 0]}>
                        <mesh geometry={nodes.wind_turbine_1.geometry} material={materials['default']} />
                        <mesh geometry={nodes.wind_turbine_2.geometry} material={materials.Velvet} />
                    </group>
                </group>
                <Environment preset="city" />
                {/*<ContactShadows position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />*/}
            </Suspense>
            {/*<OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={true} enablePan={true} />*/}
            <OrbitControls maxPolarAngle={Math.PI / 2} minDistance={20} maxDistance={60} enableZoom={true} enablePan={false} ref={(controls) => setOrbitControls(controls)}/>
        </Canvas>
        </>

    )
}
