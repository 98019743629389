import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import Footer from "../components/Footer";
import AppBarAndDrawer from "../components/NavigationComponents/AppBarAndDrawer";



export default function StructureLayout() {

  return (
    <>
      <AppBarAndDrawer />
      <Grid container
        id="containerMain"
        justifyContent="center"
        sx={{ 
          flexGrow: 1, mt: 2, paddingX:{xs:"20px", md:"50px",lg:"100px"},
          minHeight: "100vh" 
        }}>
        <Outlet />
      </Grid>
      <Footer />
    </>
  );
}


// //////lato

// /////APPBAR
// <AppBar
//             color="primary"
//             position="fixed"
//             sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
//           >
//             <Toolbar>
//               <Logo3S
//                 style={{ margin: "auto", width: 200, height: 50, paddingTop: 5 }}
//               />
//               <LogOutDetail />
//             </Toolbar>
//           </AppBar>

// //////DRAWER
// <Drawer
//             variant="permanent"
//             sx={{
//               width: drawerWidth,
//               flexShrink: 0,
//               [`& .MuiDrawer-paper`]: {
//                 width: drawerWidth,
//                 boxSizing: "border-box",
//               },
//             }}
//             PaperProps={{
//               sx: {
//                 backgroundColor: "inherit",
//               },
//             }}
//           >
//             <Toolbar />
//             <Box sx={{ overflow: "auto" }}>
//               <SidebarItems
//               />
//             </Box>
//           </Drawer>


