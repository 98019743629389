import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import projects from "../data/projects.json";
import { Box, Button, TextField, Typography } from "@mui/material";
import { StyledLink } from "../components/Theme/styledComponents";

import upload from "../img/upload.jpeg"

function ModifyProject() {
  console.log("sono dentro");
  const { id } = useParams();

  const [message, setMessage] = useState("Loading...");

  const [currentProject, setCurrentProject] = useState();
  const [name, setName] = useState();
  const [projectRegion, setProjectRegion] = useState();
  const [infrNumber, setInfrNumber] = useState();

  useEffect(() => {
    setCurrentProject(projects.filter((project) => project["id"] === id)[0]);

    if (currentProject === null || typeof currentProject === "undefined") {
      setMessage(
        "Errore, non è stato trovato nessun progetto con il seguente id: " + id
      );
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleProjectRegion = (event) => {
    setProjectRegion(event.target.value);
  };

  const handleInfrNumber = (event) => {
    setInfrNumber(event.target.value);
  };

  const saveMod = () => {
    setCurrentProject({
      name: name,
      id: id,
      region: projectRegion,
      infrastructuresNumber: infrNumber,
    });
  };

  return (
    <>
      {currentProject ? (
        <Box margin={4}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h5">Nome progetto:</Typography>
            <TextField
              color="primary"
              style={{ marginLeft: "10px" }}
              placeholder={currentProject.name}
              onChange={handleName}
              variant="outlined"
            />
          </Box>

          {/* <Typography variant="body1" align="center"></Typography> */}
          <Box height={400} width={800} bgcolor="grey" marginBottom={2}>
            <img
              src={upload}
              alt="TEST IMMAGINE"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          <Box display="flex">
            <Box display="flex" alignItems="center" marginRight={2}>
              <Typography variant="body1" component="span" marginRight={1}>
                Regione:
              </Typography>
              <Typography variant="body1" component="span">
                {currentProject.region}
              </Typography>
              <TextField
                color="primary"
                style={{ marginLeft: "10px" }}
                placeholder={currentProject.region}
                onChange={handleProjectRegion}
                variant="outlined"
              />
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="body1" component="span" marginRight={1}>
                Numero di infrastrutture:
              </Typography>
              <Typography variant="body1" component="span">
                {currentProject.infrastructuresNumber}
              </Typography>
              <TextField
                color="primary"
                style={{ marginLeft: "10px" }}
                placeholder={currentProject.infrastructuresNumber}
                onChange={handleInfrNumber}
                variant="outlined"
              />
            </Box>
          </Box>

            <Button
              component={StyledLink}
              to='#' 
              variant="contained" 
              color="primary"
              onClick={saveMod} 
              >
              SALVA
            </Button>
        </Box>
      ) : (
        <>
          <h1 style={{ textAlign: "center", display: "block", margin: "auto" }}>
            {message}
          </h1>
        </>
      )}
    </>
  );
}

export default ModifyProject;

    //  <>
    //   {currentProject ?
    //     <>
    //       <div style={{margin: "50px"}}>
    //        <div style={{display: "flex",alignItems: "center"}}>
    //           <h3>Nome progetto: </h3>
    //           <input style={{height: "20px", marginLeft: "10px"}} placeholder={currentProject.name} onChange={handleName} type="text" />
    //         </div>

    //          <div style={{height: "400px", width: "1000px", background: "grey"}}>
    //           <p style={{textAlign: "center"}}></p>
    //          </div>

    //          <ul style={{display: "flex"}}>
    //           <div style={{display: "flex", alignItems: "center"}}>
    //             <li style={{margin: "10px", listStyle: "none"}}>Regione: {currentProject.region}</li>
    //             <input style={{height: "20px", marginLeft: "10px"}} placeholder={currentProject.region} onChange={handleProjectRegion} type="text" />
    //           </div>

    //         <div style={{display: "flex", alignItems: "center"}}>
    //           <li style={{margin: "10px", listStyle: "none"}}>Numero di infrastrutture: {currentProject.infrastructuresNumber}</li>
    //           <input style={{height: "20px", marginLeft: "10px"}} placeholder={currentProject.infrastructuresNumber} onChange={handleInfrNumber} type="text" />
    //         </div>

    //         </ul>
    //         <StyledLink style={{textDecoration: "none"}}
    //         // to={"/" + id}>
    //         to={'#'}>
    //           <Button onClick={saveMod} variant="contained">SALVA</Button></StyledLink>
    //       </div>
    //       </>
