import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { EndCalendar, StartCalendar, DateFieldStyled } from '../Theme/styledComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

function CalendarRange({ handleStartDateChange, handleEndDateChange, startDate, endDate}) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [key, setKey] = useState(dayjs().utc());

    const handleReset = () => {
        handleStartDateChange(null);
        handleEndDateChange(null);
        setIsAccordionOpen(false);
        setKey(dayjs().utc());
    }
    return (
        <Box sx={{ display: "flex" }} >
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={"it"}
            >
                <Accordion
                    expanded={isAccordionOpen}
                    onChange={(event, isExpanded) => setIsAccordionOpen(isExpanded)}
                    elevation={0}
                    sx={{
                        width: { sm: "82%", md: "64%", lg: "54%", xl: "39%" },
                        position: "absolute",
                        zIndex: 2,
                        backgroundColor: "#1C1B1F"
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore sx={{ color: "whitesmoke" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ flexDirection: "row-reverse", width:'100%' }}

                    >
                        <Typography sx={{ pl: 1 }}>Date Range</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ position: "relative", width:'100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: { sm: "flex-Start" },
                                gap: 2,
                                borderRadius: 2,
                                padding: { md: 2 },
                                height: "auto"
                                , width:'100%'
                            }}
                        >
                            <Box flexDirection="column" sx={{width:'90%'}} >
                                <DateFieldStyled
                                    format="DD-MM-YYYY"
                                    label="Data di inizio"
                                    // closeOnSelect={false}
                                    // disableOpenPicker={true}
                                    readOnly
                                    value={startDate}
                                    slotProps={{
                                        textField: { helperText: "dd-mm-yyyy" },
                                    }}
                                />
                                <StartCalendar
                                    key={key}
                                    onChange={handleStartDateChange}
                                    views={['year', 'month', 'day']}
                                    yearsPerRow={4}
                                    sx={{
                                        width: '100%',
                                        marginTop: 1,
                                        height: "100%",
                                        borderRadius: 2,
                                        backgroundColor: "background.calendar",
                                    }}
                                    slotProps={{
                                        day: {
                                            sx: {
                                                "&.MuiPickersDay-root.Mui-selected": {
                                                    backgroundColor: "blue.progress",
                                                    color: "white.main"
                                                },
                                            }
                                        },
                                    }}
                                />
                            </Box>
                            <Box flexDirection="column" sx={{ width:'90%'}} >
                                <DateFieldStyled
                                    format="DD-MM-YYYY"
                                    label="Data di fine"
                                    value={endDate}
                                    // minDate={startDate}
                                    readOnly
                                    slotProps={{
                                        textField: { helperText: "dd-mm-yyyy" },
                                    }}
                                />
                                <EndCalendar
                                    key={key}
                                    onChange={handleEndDateChange}
                                    views={['year', 'month', 'day']}
                                    yearsPerRow={4}
                                    minDate={startDate}
                                    // disablePast
                                    sx={{
                                        width:'100%',
                                        marginTop: 1,
                                        height: "100%",
                                        borderRadius: 2,
                                        backgroundColor: "background.calendar",
                                    }}
                                    slotProps={{
                                        // selected: true,
                                        day: {
                                            sx: {
                                                "&.MuiPickersDay-root.Mui-selected": {
                                                    backgroundColor: 'blue.progress',
                                                    color: "white.main"
                                                },
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                            <Button
                                variant="text"
                                onClick={() => {setIsAccordionOpen(false)
                                }}
                                sx={{
                                    backgroundColor: '#00000000',
                                    color: "blue.progress",
                                  }}
                            >
                            Close
                        </Button>
                        <Button
                            variant="text"
                            onClick={handleReset}
                            sx={{
                                backgroundColor: '#00000000',
                                color: "blue.progress",
                              }}
                    >
                            Reset
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </LocalizationProvider>
        </Box >

    );
}

export default CalendarRange;