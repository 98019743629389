export const navigationMenuItem = [
  { 
    itemKey: 'Menu', 
    itemText: 'Menu', 
    linkTo: '#', 
    groups: [],
  },
  { 
    itemKey: 'Lista Progetti', 
    itemText: 'Lista Progetti', 
    linkTo: '/project',
    groups: ["admin", "adminRead", "superAdmin", "user"],
  },
  { 
    itemKey: 'Aggiungi Progetto', 
    itemText: 'Aggiungi Progetto', 
    linkTo: '/addProject', 
    groups: ["adminRead"],
  },

]


export const drawerMenuItems = [
    { 
      itemKey: 'Pagina Iniziale', 
      itemText: 'Pagina Iniziale', 
      linkTo: '/', 
      groups: [],
      submenuItems: [
        { itemKey: 'CreaioneCatalogoAnalisi', itemText: 'Creazione Catalogo analisi', linkTo: '/creationAnalysis', groups: ["admin"] },
        { itemKey: 'SubMenu2', itemText: 'SubMenu 2', linkTo: '#', groups: ['adminRead'] },
      ],
    },
    { 
      itemKey: 'Menu Drawer 2', 
      itemText: 'Lista Progetti', 
      linkTo: '/project', 
      groups: ["admin", "adminRead"],
      submenuItems: [
        { itemKey: 'SubMenu1', itemText: 'SubMenu 1', linkTo: '#', groups: ['admin', "superAdmin"] },
        { itemKey: 'SubMenu2', itemText: 'SubMenu 2', linkTo: '#', groups: ['adminRead'] },
      ],
    },
    { 
      itemKey: 'Menu Drawer 3', 
      itemText: 'Menu Drawer 3', 
      linkTo: '#', 
      groups: ["adminRead"],
      submenuItems: [
        { itemKey: 'SubMenu1', itemText: 'SubMenu 1', linkTo: '#', groups: ['adminRead'] },
        { itemKey: 'SubMenu2', itemText: 'SubMenu 2', linkTo: '#', groups: ['admin', "superAdmin"] },
      ],
    },
  ];