import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import { ReactComponent as deleteIcon } from '../icon/deleteIcon.svg'
import StyledDropzone from '../components/UI/DropZone';

function AddInfrastructure() {

  const [file, setFile] = useState([]);

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      type: '',
      total_sensors: '',
      model_TwoD: [],
    }
  });


  const handleReset = () => {
    reset();
  }
  const onSubmit = (data) => {

    console.log(data)
  }

  useEffect(() => {
    setValue('model_TwoD', file);
  }, [file, setValue]);   
  
  return (
    <>
      <Grid item container direction='column' alignItems={'center'}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

          <Typography variant="h4" fontWeight={700}>Aggiungi infrastruttura</Typography>
        </Box>

        <Divider variant='middle' sx={{ width: '100%', mt: 2 }} />

        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} width={400} sx={{ mt: 2 }}>
              <TextField
                {...register('name', {
                  required: 'Obbligatorio',
                  minLength: { value: 5, message: 'Almeno 5 caratteri' },
                  maxLength: { value: 10, message: 'Massimo 10 caratteri' }
                })}
                autoComplete='off'
                label='Nome'
                variant='outlined'
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                {...register('type', {
                  required: 'Obbligatorio',
                  minLength: { value: 5, message: 'Almeno 5 caratteri' },
                  maxLength: { value: 10, message: 'Massimo 10 caratteri' }
                })}
                autoComplete='off'
                label='Type'
                variant='outlined'
                error={!!errors.type}
                helperText={errors.type?.message}
              />
              <TextField
                {...register('total_sensors', {
                  required: 'Obbligatorio',
                  pattern: { value: /^[1-9]*$/, message: 'Solamente numeri da 1' }
                })}
                autoComplete='off'
                label='Sensori totali'
                variant='outlined'
                error={!!errors.total_sensors}
                helperText={errors.total_sensors?.message}
              />
              <Stack direction="row" alignItems="center" spacing={2}>

                <Box>
                  <StyledDropzone
                    acceptedFileFormats={{
                      'image/*': ['.jpeg', '.png']
                    }}
                    file={file}
                    setFile={setFile}
                  />
                </Box>


              </Stack>
              <Stack direction="row" alignItems="flex-start" spacing={2} justifyContent='space-between'>
                <Stack direction="row" alignItems="flex-start" spacing={2}>
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    startIcon={<SvgIcon component={deleteIcon} viewBox="0 0 17 17" />}
                    variant="contained"
                    sx={{
                      backgroundColor: "secondary.main",
                      '&:hover': {
                        backgroundColor: "secondary.hover"
                      }
                    }}  >
                    Cancel
                  </Button>
                </Stack>
                <Button
                  onClick={handleReset}
                  variant="text"
                  sx={{
                    textDecoration: "underline",
                    backgroundColor: "#00000000",
                    color: "brightAzure.main",
                    '&:hover': {
                      color: "brightAzure.hover",
                      backgroundColor: "#00000000",
                    }
                  }}  >
                  Reset form
                </Button>
              </Stack>
            </Stack>

          </form>
        </Box>

      </Grid>
    </>
  )
}

export default AddInfrastructure;