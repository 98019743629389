import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { Done as DoneIcon } from "@mui/icons-material";
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Typography} from "@mui/material";
import {useState} from "react";
// import {useNavigate} from "react-router-dom";
import "./ChangePassword.css";
import { useTheme } from "@emotion/react";

/*
Requisiti delle password:
Lunghezza minima 8 caratteri
Contiene almeno 1 numero
Contiene almeno 1 carattere speciale (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =)
Contiene almeno 1 carattere maiuscolo
Contiene almeno 1 carattere minuscolo
^$*.[]{}()?-"!@#%&,><':;|_~`+=
*/


const ChangePassword = () => {
    const policy = ['^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '-', '"', '!', '@', '#', '%', '&', '/', ',', '<', '>', "'", ':', ';', '|', '_', ' ~', '`', '+', '=']
    const { user
        // , signOut 
    } = useAuthenticator((context) => [context.user]);
    const [error, setError] = useState(false)
    const [errorConfrim, setErrorConfirm] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    // const navigate = useNavigate();

    const onOldPasswordChange = (event) => {
        setOldPassword(event.target.value)
    }

    const checkPolicy = (event) => {
        //console.log(event.target.value.match(/[ $ * .  { } ( ) ?  " ! @ # % &  \ , > < ' : ; | _ ~ ` + =]/))
        if (!(policy.some(el => event.target.value.includes(el)) && event.target.value.length > 7 && event.target.value !== event.target.value.toUpperCase() && event.target.value !== event.target.value.toLowerCase() && /\d/.test(event.target.value))) {
            setError(true)
        }
        else
            setError(false)
    }

    const onNewPasswordChange = (event) => {
        setNewPassword(event.target.value)
        checkPolicy(event)
    }

    const onConfirmPassChange = (event) => {
        if (event.target.value !== newPassword)
            setErrorConfirm(true)
        else
            setErrorConfirm(false)
    }


    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseDialog = () => {
        setOpenSuccessDialog(false);
        setOpenErrorDialog(false);
    };

    const submitNewPassword = async () => {
        if (!(errorConfrim && error)) {
            Auth.changePassword(user, oldPassword, newPassword)
                .then(success => {
                    setOpenSuccessDialog(true);
                    setOldPassword('');
                    setNewPassword('');
                })
                .catch(err => {
                    setErrorMessage(err.name);
                    setOpenErrorDialog(true);
                })
        }
    }

    const theme = useTheme();

    return (
        <>
            {/* Dialog for successful operation */}
            <Dialog
                open={openSuccessDialog}
                // onClose={handleCloseDialog}
            >
                <DialogTitle>Eseguito</DialogTitle>
                <DialogContent>
                    <DialogContentText>Cambio password avvenuto correttamente</DialogContentText>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogContent>
            </Dialog>

            {/* Dialog for error */}
            <Dialog
                open={openErrorDialog}
                // onClose={handleCloseDialog}
            >
                <DialogTitle>Oops...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Si è verificato un problema! <br /> {errorMessage}
                    </DialogContentText>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogContent>
            </Dialog>

            <Box className="containernew" sx={{bgcolor: theme.palette.primary.transparentMain}}>
                <TextField
                    color="primary"
                    id="old Password"
                    label="Vecchia Password"
                    variant="standard"
                    type="password"
                    onChange={onOldPasswordChange}
                />
                <br />
                <br />
                <TextField
                    color="primary"
                    error={error}
                    id="newPassword"
                    label="Nuova Password"
                    variant="standard"
                    type="password"
                    onChange={onNewPasswordChange}
                />
                <br />
                <br />
                <TextField
                    color="primary"
                    error={errorConfrim}
                    id="confrimnewPassword"
                    label="Conferma Password"
                    helperText={errorConfrim ? "Le password devono corrispondere" : null}
                    variant="standard"
                    type="password"
                    onChange={onConfirmPassChange}
                />
                {!error ? (null) :
                    <Typography sx={{ color: 'error.main', marginTop: 2 }}>
                        La nuova password deve contenere almeno 8 caratteri di cui almeno uno speciale, un numero, una lettera maiuscola ed una minuscola
                    </Typography>}
                <Button
                    color="primary"
                    variant='contained'
                    endIcon={<DoneIcon />}
                    sx={{ marginLeft: 15, marginTop: 5 }}
                    onClick={submitNewPassword}>
                    Applica modifica
                </Button>
            </Box>
        </>
    );
};

export default ChangePassword;